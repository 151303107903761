import React from "react";
import { useNavigate } from "react-router";
import { AssetReportCheckEdge, AssetReportQueryEdge } from "../../asset/types";
import { SelectedEntity } from "~/components/exceptions/types";
import { Space } from "~/lib/types";
import {
  AssetReportException,
  MqueryDocs,
  MqueryState,
  MqueryType,
  ReviewStatus,
} from "~/operations";
import { stateFromScore } from "~/lib/score";
import { PolicyQueryAssessmentResults } from "~/components/policy-query-assessment-results";
import { Code } from "~/components/code";
import { Markdown } from "~/components/markdown";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { DetailRow } from "~/components/data-table";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { BlockIcon, ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { MoonIcon } from "~/components/ui-library";
import { formatPolicyName } from "../../asset/asset-checks-tab";
import { ErrorBoundary } from "~/components/error-boundary";
import { ImpactResult } from "~/components/impact";
import { ImpactCell } from "~/components/Cells";

type AssetReportQueryRowProps = {
  space: Space;
  assetId: string;
  queryEdge: AssetReportCheckEdge;
  isFocused?: boolean;
  isSelectable?: boolean;
  isChecked?: boolean;
  handleOpen?: (queryEdge: AssetReportCheckEdge) => void;
  handleClose?: (queryEdge: AssetReportCheckEdge) => void;
  handleCheck?: (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    exception: SelectedEntity,
  ) => void;
};

const QueryListItem = styled(ListItem)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Headline = styled(Typography)`
  padding-bottom: 0.3em;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export const getQueryStatus = (
  node: AssetReportCheckEdge["node"] | AssetReportQueryEdge["node"],
) => {
  const { mqueryType, score } = node;
  if (mqueryType === MqueryType.Data) return "data";
  switch (stateFromScore(score)) {
    case 4:
      return "error";
    case 3:
      return "fail";
    case 2:
      return "skip";
    case 1:
      return "pass";
    case 0:
    default:
      return "unknown";
  }
};

export const getQueryResults = (
  node: AssetReportCheckEdge["node"] | AssetReportQueryEdge["node"],
) => {
  let results = undefined;
  const { assessment, data } = node;
  let status = getQueryStatus(node);
  if (assessment && status === "fail") {
    results = (
      <PolicyQueryAssessmentResults assessment={assessment} status={status} />
    );
  } else if (data) {
    try {
      results = (
        <Code className="plaintext">{JSON.stringify(node.data, null, 2)}</Code>
      );
    } catch {
      results = (
        <Code className="plaintext">
          An error occurred while processing results
        </Code>
      );
    }
  }
  return results;
};

export function ChecksTableRow({
  space,
  assetId,
  queryEdge,
  isFocused = false,
  isSelectable = false,
  isChecked = false,
  handleOpen,
  handleClose,
  handleCheck,
}: AssetReportQueryRowProps) {
  const [open, setOpen] = React.useState<boolean>(isFocused);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isFocused) setOpen(isFocused);
  }, [isFocused]);

  const handleClick = () => {
    if (open) {
      setOpen(false);
      handleClose?.(queryEdge);
    } else {
      setOpen(true);
      handleOpen?.(queryEdge);
    }
  };

  const handlePendingReviewClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    exception: AssetReportException,
  ) => {
    e.stopPropagation();
    navigate(
      `/space/inventory/${assetId}/exceptions?spaceId=${space.id}&exceptionId=${exception.id}`,
    );
  };

  const { mquery, mqueryState, score, exception } = queryEdge.node;
  const { mrn, title, impact, docs, mql } = mquery;

  const results = getQueryResults(queryEdge.node);
  const isModified = mqueryState !== MqueryState.Enabled;
  const isSnoozed = mqueryState === MqueryState.Snoozed;
  const isDisabled = mqueryState === MqueryState.Disabled;
  const hasQueryError =
    score.message && stateFromScore(queryEdge.node.score) === 4;
  const isPendingException =
    exception?.reviewStatus === ReviewStatus.NotReviewed;

  const descText = docs?.desc ? <Markdown source={docs.desc} /> : null;
  const auditText = docs?.audit ? <Markdown source={docs.audit} /> : null;

  // extract remediation text from nested docs structure
  const getRemediationText = (remediations: MqueryDocs["remediations"]) => {
    if (
      remediations &&
      "entries" in remediations &&
      remediations.entries.length > 0
    ) {
      return remediations.entries.map((remediation) => (
        <Markdown key={remediation.id} source={remediation.desc} />
      ));
    }
    return null;
  };

  const remediationText = getRemediationText(docs?.remediations);
  const className = [isChecked ? "selected" : ""].join(" ");

  return (
    <React.Fragment key={mrn}>
      {/* Accordion Title Row */}
      <TableRow id={mrn} onClick={handleClick} className={className}>
        {isSelectable && (
          <TableCell>
            <Checkbox
              onChange={(e, checked) =>
                handleCheck?.(e, checked, {
                  mrn,
                  exception,
                  groupId: exception?.id,
                })
              }
              checked={isChecked}
              onClick={(e) => e.stopPropagation()}
            />
          </TableCell>
        )}
        {impact && (
          <ImpactCell
            className="impact-cell"
            impact={impact}
            isActive={!isModified}
            isRiskScore={false}
            score={score}
          />
        )}
        {/* <TableCell
          sx={{ opacity: isModified ? 0.5 : 1 }}
          className="impact-cell"
        >
          {impact && (
            <ImpactResult
              impact={impact.value}
              isActive={!isModified}
              score={score}
            />
          )}
        </TableCell> */}
        <TableCell
          sx={{ opacity: isModified ? 0.5 : 1, overflowWrap: "anywhere" }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              {isSnoozed && (
                <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
              )}
              {isDisabled && (
                <Chip label="Disabled" icon={<BlockIcon />} size="small" />
              )}
              {isPendingException && (
                <NavigateToExceptionButton
                  onClick={(e) => {
                    handlePendingReviewClick(e, exception);
                  }}
                />
              )}
              <Typography
                className="mquery-title"
                fontSize={14}
                display="inline-block"
              >
                {title}
              </Typography>
            </Box>
            <List sx={{ p: 0 }}>
              {queryEdge.node.policies?.map((p) => (
                <ListItem sx={{ p: 0 }} key={formatPolicyName(p, space.id)}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    fontSize={10}
                  >
                    {formatPolicyName(p, space.id)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Accordion Content Row */}
      <DetailRow
        id={`${mrn}-expand`}
        colSpan={isSelectable ? 5 : 4}
        open={open}
      >
        <List>
          <QueryListItem key="query">
            <ListItemText
              primary={
                <>
                  <Headline variant="h6">Query</Headline>
                  <Code copyButton className="javascript">
                    {mql.trim()}
                  </Code>
                </>
              }
            />
          </QueryListItem>
          {(results || hasQueryError) && (
            <QueryListItem key="result">
              <ListItemText
                primary={
                  <>
                    <Headline variant="h6">Result</Headline>
                    {hasQueryError && (
                      <Code copyButton className="ini">
                        {"[Query Error]\n" + score.message}
                      </Code>
                    )}
                    {results}
                  </>
                }
              />
            </QueryListItem>
          )}
          {descText && (
            <QueryListItem key="description">
              <ListItemText
                primary={
                  <>
                    <Headline variant="h6">Description</Headline>
                    {descText}
                  </>
                }
              />
            </QueryListItem>
          )}
          {auditText && (
            <QueryListItem key="audit">
              <ListItemText
                primary={
                  <>
                    <Headline variant="h6">Audit</Headline>
                    {auditText}
                  </>
                }
              />
            </QueryListItem>
          )}
          {remediationText && (
            <ErrorBoundary
              key={`${mrn}-remediation`}
              FallbackComponent={RowErrorFallback}
            >
              <QueryListItem key="remediation">
                <ListItemText
                  primary={
                    <>
                      <Headline variant="h6">Remediation</Headline>
                      {remediationText}
                    </>
                  }
                />
              </QueryListItem>
            </ErrorBoundary>
          )}
        </List>
      </DetailRow>
    </React.Fragment>
  );
}

const RowErrorFallback = ({ error }: { error: Error }) => {
  console.error(error.message);
  return (
    <TableRow>
      <TableCell colSpan={5} sx={{ border: "none" }}></TableCell>
    </TableRow>
  );
};
