import { Box, Button, Grid, Typography } from "@mui/material";
import { AvailableIntegration } from "~/components/integrations/cards/available-integration-cards";
import { IntegrationCardsList } from "~/components/integrations/integration-cards-list";
import { Space } from "~/lib/types";
import {
  BottomBanner,
  BottomBanner2x,
  BottomBannerLight,
  BottomBannerLight2x,
} from "~/images/icons/temporary-icons";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { isFeatureEnabled } from "~/login/features";
import { useColorMode } from "~/providers/color-mode";

type Props = {
  space: Space;
};

export function AvailableIntegrations({ space }: Props) {
  const navDrawer = useAppNavDrawer();
  const { mode } = useColorMode();
  return (
    <Box component={motion.div} {...fadeInOut} key="available-integrations">
      {/* Recommended Section */}
      <Grid container item columnSpacing={3} rowGap={3} mt={5} mb={10}>
        <IntegrationCardsList
          recommended
          space={space}
          integrations={recommendedIntegrations}
        />
      </Grid>

      {/* cnquery and cnspec */}
      <IntegrationCardsList
        title="Security Engineering"
        space={space}
        integrations={workstationIntegrations}
      />

      {/* Server and Endpoint Security */}
      <IntegrationCardsList
        title="Server &amp; Endpoint Security"
        space={space}
        integrations={serverEndpointSecurityIntegrations}
      />

      {/* Cloud Security */}
      <IntegrationCardsList
        title="Cloud Security"
        space={space}
        integrations={cloudSecurityIntegrations}
      />

      {/* Supply Chain */}
      <IntegrationCardsList
        title="Supply Chain"
        space={space}
        integrations={softwareSupplyChainIntegrations}
      />

      {/* SaaS */}
      <IntegrationCardsList
        title="SaaS"
        space={space}
        integrations={saasIntegrations}
      />

      {/* Network Security */}
      <IntegrationCardsList
        title="Network Security"
        space={space}
        integrations={networkSecurityIntegrations}
      />

      {/* CI/CD */}
      <IntegrationCardsList
        title="CI/CD"
        space={space}
        integrations={cicdIntegrations}
      />

      {/* Exports */}
      <IntegrationCardsList
        title="Exports"
        space={space}
        integrations={exportIntegrations}
      />

      {/* Ticket Systems */}
      <IntegrationCardsList
        title="Ticket Systems"
        space={space}
        integrations={ticketIntegrations}
      />

      {/* Chat Ops */}
      <IntegrationCardsList
        title="Chat Ops"
        space={space}
        integrations={chatOpsIntegrations}
      />

      {/* Bottom Banner */}
      <Box
        mt={6}
        sx={{
          position: "absolute",
          left: navDrawer.width,
          transition: (theme) =>
            theme.transitions.create("left", navDrawer.transition),
          right: 0,
          // the component has a background to hide the mondoo footer logo
          backgroundColor: "background.default",
        }}
      >
        <Box
          position="relative"
          zIndex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: {
              xs: -2,
              sm: "3%",
              md: "4%",
            },
          }}
        >
          <Typography
            fontFamily="Roboto"
            fontWeight={900}
            sx={{
              mb: {
                xs: "3%",
                sm: "1%",
                md: "1%",
              },
              fontSize: { xs: 16, sm: 24, md: 40 },
            }}
          >
            Didn't find what you were looking for?
          </Typography>
          <Button
            href="mailto:hello@mondoo.com?subject=Requesting an integration!"
            data-name="request-integration-contact-button"
            sx={{ background: (theme) => theme.palette.primary.gradient }}
          >
            Request An Integration
          </Button>
        </Box>
        <Box
          component="img"
          src={mode === "light" ? BottomBannerLight : BottomBanner}
          srcSet={
            mode === "light"
              ? `${BottomBannerLight2x} 2x`
              : `${BottomBanner2x} 2x`
          }
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            display: "inline-block",
            maxWidth: "100%",
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
}

// Integration Card Data

const recommendedIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/aws",
    title: "AWS",
    description:
      "Continuously scan AWS organizations, accounts, and instances for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/setup",
    title: "Workstation",
    description:
      "Deploy cnspec on your workstation to scan remote targets, perform live queries, and generate reports about your assets.",
  },
  {
    id: "mondoo/kubernetes",
    title: "Kubernetes",
    description:
      "Deploy the Mondoo Kubernetes Operator to enable continuous workload & node security scanning with optional scanning of new deployments.",
  },
];

const workstationIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/setup",
    title: "Workstation",
    description:
      "Deploy cnspec on your workstation to scan remote targets, perform live queries, and generate reports about your assets.",
  },
];

const serverEndpointSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/redhat",
    title: "Red Hat/Fedora",
    description:
      "Deploy cnspec on your Red Hat Linux (RHEL) and Fedora Linux based hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/windows",
    title: "Windows",
    description:
      "Deploy cnspec on your Windows hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/ubuntu",
    title: "Ubuntu/Debian",
    description:
      "Deploy cnspec on your Ubuntu & Debian Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/amazonlinux",
    title: "Amazon Linux",
    description:
      "Deploy cnspec on your Amazon Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/suse",
    title: "SUSE/openSUSE",
    description:
      "Deploy cnspec on your SUSE/openSUSE Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/macos",
    title: "macOS",
    description:
      "Deploy cnspec on your macOS hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
];

const cloudSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/aws",
    title: "AWS",
    description:
      "Continuously scan AWS organizations, accounts, and instances for misconfigurations and vulnerabilities.",
    flags: null,
  },
  {
    id: "mondoo/azure",
    title: "Azure",
    description:
      "Continuously scan Microsoft Azure subscriptions and resources for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/gcp",
    title: "GCP",
    description:
      "Continuously scan Google GCP organizations and projects for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/kubernetes",
    title: "Kubernetes",
    description:
      "Deploy the Mondoo Kubernetes Operator to enable continuous workload & node security scanning with optional scanning of new deployments.",
    flags: null,
  },
  {
    id: "mondoo/vmware",
    title: "VMware",
    description:
      "Deploy the Mondoo VMware Appliance to scan VMware vCenter Server, vSphere API, ESXi, and virtual machines.",
    flags: null,
  },
  {
    id: "mondoo/oci",
    title: "Oracle Cloud Infrastructure",
    description:
      "Continuously scan Oracle Cloud Infrastructure (OCI) tenancies and resources for misconfigurations and vulnerabilities.",
    flags: null,
  },
];

if (isFeatureEnabled("Defender Integration")) {
  cloudSecurityIntegrations.push({
    id: "mondoo/defender",
    title: "Defender",
    description:
      "Continuously scan Microsoft Defender subscriptions and resources for misconfigurations and vulnerabilities.",
    flags: null,
  });
}

const softwareSupplyChainIntegrations: AvailableIntegration[] = [
  {
    id: "hashicorp/packer",
    title: "Packer",
    description:
      "Use the Mondoo HashiCorp Packer provisioner to scan for policy compliance and vulnerabilities during an image build.",
    flags: null,
  },
  {
    id: "hashicorp/terraform",
    title: "Terraform",
    description:
      "Use Mondoo to run a static analysis of HashiCorp Terraform (HCL) code before it's run, or run Mondoo as a post-provisioning step in your Terraform workflows.",
    flags: null,
  },
  {
    id: "mondoo/credentials",
    title: "Generate Long-Lived Credentials",
    description:
      "Integrate Mondoo into any custom automated workflow by generating a long-lived credential file.",
    flags: null,
  },
];

const saasIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/ms365",
    title: "Microsoft 365",
    description:
      "Continuously scan Microsoft 365 resources for security misconfigurations.",
    flags: null,
  },
  {
    id: "mondoo/okta",
    title: "Okta",
    description:
      "Continuously scan your Okta domain including users, groups, policies, rules, applications and more.",
    flags: null,
  },
  {
    id: "mondoo/google_workspace",
    title: "Google Workspace",
    description:
      "Continuously scan your Google Workspace environment for security misconfigurations.",
    flags: null,
  },
  {
    id: "mondoo/gitlab",
    title: "GitLab",
    description:
      "Continuously scan your GitLab groups for security misconfigurations",
  },
  {
    id: "mondoo/github",
    title: "GitHub",
    description:
      "Continuously scan your GitHub repositories for security misconfigurations",
  },
  {
    id: "mondoo/slack",
    title: "Slack",
    description:
      "Continuously scan your Slack Teams for security misconfigurations",
    flags: null,
  },
];

const networkSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/host",
    title: "Domain/IP Address",
    description:
      "Continuously scan endpoints to evaluate domain TLS, SSL, HTTP, and HTTPS security",
    flags: null,
  },
];

const ticketIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/ticket_system_jira",
    title: "Atlassian Jira",
    description:
      "To incorporate Mondoo into your Jira workflow, automatically create a Jira issue when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_email",
    title: "Ticketing via email",
    description:
      "To incorporate Mondoo into your regular tracking workflow, send email to your ticketing system when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_zendesk",
    title: "Zendesk",
    description: "Create and manage cases in sync with your zendesk crm",
    flags: null,
  },
];

const cicdIntegrations: AvailableIntegration[] = [
  {
    id: "cicd/circleci",
    title: "CircleCI",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your CirceCI projects.",
    flags: null,
  },
  {
    id: "cicd/github",
    title: "GitHub Actions",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your GitHub Actions.",
    flags: null,
  },
  {
    id: "cicd/gitlab",
    title: "GitLab CI/CD",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your GitLab CI/CD pipelines.",
    flags: null,
  },
  {
    id: "cicd/azure",
    title: "Azure Pipelines",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your Azure Pipelines.",
    flags: null,
  },
  {
    id: "cicd/jenkins",
    title: "Jenkins",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your Jenkins pipelines.",
    flags: null,
  },
];

const exportIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/bigquery",
    title: "BigQuery",
    description:
      "Continuously share scan results with Google BigQuery for storage, analysis, and reporting.",
    flags: null,
  },
  {
    id: "mondoo/snowflake",
    title: "Snowflake",
    description:
      "Continuously share scan results with Snowflake for storage, analysis, and reporting.",
    flags: null,
  },
  {
    id: "mondoo/amazons3",
    title: "Amazon S3",
    description: "Continuously export data to Amazon S3 in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/amazon-s3-compatible",
    title: "S3 Compatible Service",
    description:
      "Continuously export data to Amazon S3-compatible services in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/postgres",
    title: "PostgreSQL",
    description: "Continuously export data to a PostgreSQL database",
    flags: null,
  },
  {
    id: "mondoo/gcs_bucket",
    title: "GCP Cloud Storage Bucket",
    description:
      "Continuously export data to GCP Cloud Storage in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/azure_blob",
    title: "Azure Blob Storage",
    description:
      "Continuously export data to Azure Blob Storage in JSONL or CSV format",
    flags: null,
  },
  isFeatureEnabled("CloudSQL")
    ? {
        id: "mondoo/cloudsql-postgresql",
        title: "Cloud SQL PostgreSQL",
        description: "Continuously export data to GCP Cloud SQL for PostgreSQL",
        flags: null,
      }
    : null,
].flatMap((x) => (x !== null ? [x] : []));

const chatOpsIntegrations: AvailableIntegration[] = [
  {
    id: "chat/msteams",
    title: "Microsoft Teams",
    description:
      "Send alert messages and notifications from Mondoo directly to Microsoft Teams.",
    flags: null,
  },
  {
    id: "chat/slack",
    title: "Slack",
    description:
      "Send alert messages and notifications from Mondoo directly to a Slack channel.",
    flags: null,
  },
  {
    id: "chat/telegram",
    title: "Telegram",
    description:
      "Send alert messages and notifications from Mondoo directly to Telegram.",
    flags: null,
  },
  {
    id: "chat/httppost",
    title: "Outbound Webhook",
    description:
      "Send alert messages and notifications from Mondoo as JSON-encoded data to any HTTP endpoint.",
    flags: null,
  },
];
