import { Fragment } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import {
  GetClientIntegrationDocument,
  IntegrationMessageStatus,
  TestIamActionsQuery,
  useSuppressIntegrationMessageMutation,
} from "~/operations";
import { CloseIcon } from "~/components/icons";
import { IamActions } from "~/lib/iam";
import { useSnackbar } from "notistack";
import { DetailedMessage } from "./aws-integration-message";
import { AwsIntegration } from "./integration-aws-account-serverless";

type Props = {
  awsIntegration: AwsIntegration;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function AwsIntegrationAlert({
  awsIntegration,
  availablePermissions,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [suppressIntegrationMessage] = useSuppressIntegrationMessageMutation();
  const getSeverityForMessageStatus = (
    status: IntegrationMessageStatus,
  ): AlertColor => {
    switch (status) {
      case IntegrationMessageStatus.Error:
        return "error";
      case IntegrationMessageStatus.Warning:
        return "warning";
      case IntegrationMessageStatus.Info:
        return "info";
    }
  };

  const hasIntegrationSuppressMessagePermission =
    availablePermissions?.includes(
      IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_SUPPRESSINTEGRATIONMESSAGE,
    );

  const getActionsForMessage = (
    message?: string | null,
    identifier?: string | null,
  ) => {
    if (!message) return <Fragment />;
    const docsAction = getDocsActionForMessage(message);

    if (!identifier) return <Fragment />;
    const closeAction = getCloseActionForMessage(message, identifier);
    return (
      <Fragment>
        {docsAction}
        {closeAction}
      </Fragment>
    );
  };

  const showIntegrationMsg = (message: string, identifier?: string | null) => {
    if (!identifier) {
      return message;
    }
    return identifier + ": " + message;
  };

  const getDocsActionForMessage = (message: string) => {
    const href = getDocsHrefForMessage(message);
    return href.length > 0 ? (
      <Button color="inherit" size="small" href={href} target="_blank">
        LEARN MORE
      </Button>
    ) : undefined;
  };

  const getDocsHrefForMessage = (message: string): string => {
    if (message.includes("ssm")) {
      return "https://mondoo.com/docs/platform/infra/cloud/aws/aws-ssm-scan/";
    }
    if (message.includes("ebs")) {
      return "https://mondoo.com/docs/platform/infra/cloud/aws/aws-ebs-snapshot-scan/";
    }
    return "";
  };

  const getCloseActionForMessage = (message: string, identifier: string) => {
    return hasIntegrationSuppressMessagePermission ? (
      <IconButton
        size="small"
        aria-label="Suppress this message"
        color="inherit"
        onClick={() => handleMessageClose(message, identifier)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ) : undefined;
  };

  const handleMessageClose = async (message: string, identifier: string) => {
    try {
      await suppressIntegrationMessage({
        variables: {
          input: {
            mrn: awsIntegration.mrn,
            message: message,
            identifier: identifier,
          },
        },
        refetchQueries: [GetClientIntegrationDocument],
      });
    } catch {
      enqueueSnackbar("Error suppressing message", {
        variant: "error",
      });
    }
  };
  let waiting = awsIntegration.status === "WAITING_FOR_SETUP";
  return (
    <Fragment>
      {waiting && (
        <Fragment>
          <Alert
            variant="outlined"
            key={IntegrationMessageStatus.Info}
            severity={getSeverityForMessageStatus(
              IntegrationMessageStatus.Info,
            )}
            action={getActionsForMessage("Waiting for CloudFormation", "")}
            sx={{
              backgroundColor: "background.default",
              mb: 3,
              position: "relative",
            }}
          >
            <Box>
              <Typography variant="body2">
                {showIntegrationMsg("Waiting for CloudFormation", "")}
              </Typography>
              <DetailedMessage {...{ awsIntegration, availablePermissions }} />
            </Box>
          </Alert>
        </Fragment>
      )}
      {awsIntegration.messages?.map(({ message, status, identifier }) => {
        return (
          <Alert
            variant="outlined"
            key={status}
            severity={getSeverityForMessageStatus(status)}
            action={getActionsForMessage(message, identifier)}
            sx={{
              backgroundColor: "background.default",
              mb: 3,
              position: "relative",
            }}
          >
            <Box>
              <Typography variant="body2">
                {showIntegrationMsg(message, identifier)}
              </Typography>
              <DetailedMessage {...{ awsIntegration, availablePermissions }} />
            </Box>
          </Alert>
        );
      })}
    </Fragment>
  );
}
