import { useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { ArrowForwardIcon, HomeIcon } from "~/components/icons";
import { useSnackbar } from "notistack";
import { OthersGradientIcon } from "~/components/icons/mondoo/others-gradient";
import { NodesGradientIcon } from "~/components/icons/mondoo/nodes-gradient";

import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetIntegrationsSummaryDocument,
  IntegrationAssetCount,
  TestIamActionsQuery,
  useDeleteClientIntegrationMutation,
  useGetClientIntegrationQuery,
} from "~/operations";
import { IntegrationHeader } from "../headers/integration-header";
import { ConfigurationsAndMessages } from "../components/configurations-and-messages";
import { ConfirmDeleteDialog } from "../components/confirm-delete-dialogue";
import { mapIntegrationRouteToTitle } from "./helpers";
import { Space } from "~/lib/types";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

const EXPORT_INTEGRATIONS = [
  ClientIntegrationType.AwsS3,
  ClientIntegrationType.Bigquery,
  ClientIntegrationType.GcsBucket,
  ClientIntegrationType.Postgres,
  ClientIntegrationType.Snowflake,
  ClientIntegrationType.S3,
  ClientIntegrationType.AzureBlob,
  ClientIntegrationType.MicrosoftDefender,
];

const TICKET_SYSTEM_INTEGRATIONS = [
  ClientIntegrationType.TicketSystemJira,
  ClientIntegrationType.TicketSystemEmail,
];

export function HostedIntegrationPage({ space, availablePermissions }: Props) {
  const { integrationTypeId, integrationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchParams, _setSearchParams] = useSearchParams();
  const integrationTitle = mapIntegrationRouteToTitle(integrationTypeId);
  const mrn: string = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;

  const { data, error, loading } = useGetClientIntegrationQuery({
    variables: { mrn },
  });

  const [deleteIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  if (error) {
    return <LoadingFailedPage what="Integration" />;
  }

  if (loading || !data?.clientIntegration.integration) {
    return <LoadingPage what="Integration" />;
  }

  const { integration } = data.clientIntegration;

  // integration
  let integrationStatus: ClientIntegration["status"] = integration.status;
  if (integration.status.toLowerCase() === "not_ready") {
    integrationStatus = "pending";
  }

  const actions = {
    deleteIntegration: () => setDialogOpen(true),
    editIntegration: () => navigate(`edit?spaceId=${space.id}`),
  };

  const getAssetsUrl = (integrationMrn: string) => {
    const queryterms = JSON.stringify({
      "mondoo.com/integration-mrn": integrationMrn,
    });
    searchParams.set("queryTerms", queryterms);
    return `/space/inventory?${searchParams}`;
  };

  const handleDelete = async () => {
    try {
      await deleteIntegration({
        variables: { input: { mrn: integration.mrn } },
      });
      enqueueSnackbar(
        `Successfully removed ${integrationTitle} integration from Mondoo`,
        {
          variant: "success",
        },
      );
      navigate(`/space/integrations/${integrationTypeId}?spaceId=${space.id}`);
    } catch {
      enqueueSnackbar(
        `Could not remove ${integrationTitle} integration from Mondoo`,
        {
          variant: "error",
        },
      );
    }
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key={`/space/integrations/${integrationTypeId}`}
      component={RouterLink}
      to={`/space/integrations/${integrationTypeId}?spaceId=${space.id}`}
    >
      {integrationTitle}
    </Link>,
    <Typography key={1} color="text.primary">
      {integration.name}
    </Typography>,
  ];

  type CountsObject = { name: string; total: number; icon: JSX.Element };
  type AssetCounts = ClientIntegration["assetCounts"];

  const buildAssetCounts = (counts: AssetCounts): CountsObject[] => {
    let countsWithIcons: CountsObject[] = [];
    if (!counts) {
      return countsWithIcons;
    }
    counts.forEach((count: IntegrationAssetCount) => {
      countsWithIcons.push({
        name: count.assetType,
        total: count.total,
        icon: getIcon(count.assetType),
      });
    });
    return countsWithIcons;
  };

  const getIcon = (name: string): JSX.Element => {
    switch (name) {
      case "Compute VMs":
        return <NodesGradientIcon sx={{ fontSize: 40 }} />;
      default:
        return <OthersGradientIcon sx={{ fontSize: 40 }} />;
    }
  };
  const assetCounts = buildAssetCounts(integration.assetCounts);

  document.title = `${integration.name} · ${integrationTitle} · Integrations · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 5 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {/* Header Block */}
      <Box sx={{ mb: 6.5 }}>
        <IntegrationHeader
          {...{
            space,
            integration,
            availablePermissions,
            actions,
            integrationTypeId,
            ...([
              ...EXPORT_INTEGRATIONS,
              ...TICKET_SYSTEM_INTEGRATIONS,
            ].includes(integration.type) && {
              scanAction: ActionType.RunExport,
            }),
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Integration Details
        </Typography>
        {/* If the integration status is pending, we don't want to show a link to assets */}
        {/* If the integration is an export, there are no assets to link to */}
        {![...EXPORT_INTEGRATIONS, ...TICKET_SYSTEM_INTEGRATIONS].includes(
          integration.type,
        ) &&
          assetCounts.length > 0 &&
          integrationStatus !== "pending" && (
            <Button
              color="secondary"
              component={RouterLink}
              to={getAssetsUrl(integration.mrn)}
              endIcon={<ArrowForwardIcon />}
            >
              Show Assets
            </Button>
          )}
      </Box>
      {assetCounts.length > 0 && (
        <Grid container component={Paper} mb={3} p={1}>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{
                textTransform: "uppercase",
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.background.lightest,
                pb: 2,
              }}
            >
              Discovered Resources ({assetCounts.length})
            </Typography>
          </Grid>

          {assetCounts.map((x) => (
            <Grid item key={x.name} xs={12} sm={6} md={3}>
              <Grid container sx={{ p: 2 }}>
                <Grid item sx={{ mr: 2 }}>
                  {x.icon}
                </Grid>
                <Grid item xs>
                  <Typography
                    fontWeight={700}
                    sx={{ mb: -0.75, textTransform: "capitalize" }}
                  >
                    {x.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {x.total} Discovered
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {/* Configuration and Overview Information */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <ConfigurationsAndMessages {...{ integration }} />
      </Paper>
      {/* Delete Confirmation Dialog */}
      <ConfirmDeleteDialog
        id={`confirm-delete-single-${integrationTypeId}-item`}
        value={"hello"}
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onConfirm={handleDelete}
        title={`Remove ${integrationTitle} Integration`}
        content={
          <Typography>
            Are you absolutely sure you want to remove this integration? This
            operation cannot be undone.
          </Typography>
        }
      />
    </Box>
  );
}
