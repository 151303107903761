import { useState } from "react";
import { Policy } from "../policy-gql";
import { ScoringSystem } from "~/operations";
import { Typography } from "@mui/material";

type UsePolicyViewerProps = { policy: Policy };

export function usePolicyViewer({ policy }: UsePolicyViewerProps) {
  const [scoringSystem, setScoringSystem] = useState<ScoringSystem>(
    policy?.scoringSystem ||
      policy?.defaultScoringSystem ||
      ScoringSystem.Banded,
  );

  // create the menu items for the scoring system by looping through the ScoringSystem enum
  const scoreByMenuItems = Object.values(ScoringSystem).map((value) => {
    const formattedValue = value
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
    const label =
      value === policy.defaultScoringSystem ? (
        <Typography>
          {formattedValue}{" "}
          <Typography
            component="span"
            sx={{ fontSize: 12, color: "text.secondary" }}
          >
            (Recommended)
          </Typography>
        </Typography>
      ) : (
        formattedValue
      );

    return { value, label };
  });

  // determine the scoring system description based on the selected scoring system
  const scoringSystemDescription = {
    [ScoringSystem.Average]: "Average all findings, weighted by impact.",
    [ScoringSystem.Banded]: "A risk-aware weighting of scores.",
    [ScoringSystem.Decayed]:
      "Higher-risk findings quickly decrease the overall score.",
    [ScoringSystem.HighestImpact]:
      "The overall is equal to the finding with the worst score.",
    [ScoringSystem.WeightedAverage]:
      "Compute a weighted average of all findings.",
  }[scoringSystem];

  return {
    scoringSystem,
    setScoringSystem,
    scoreByMenuItems,
    scoringSystemDescription,
  };
}
