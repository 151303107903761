import { Fragment, useState } from "react";
import {
  alpha,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableBody,
  Collapse,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";

import { Flex } from "~/components/ui-library";
import { Code } from "~/components/code";
import { ErrorBoundary } from "~/components/error-boundary";
import {
  PolicyQueryAssessmentResources,
  Resource,
} from "~/pages/inventory/asset/types";
import { useAffectedResources } from "./use-affected-resources";
import { AffectedResourcesHeader } from "./affected-resources-header";
import {
  CopyTableToClipboard,
  CopyTableToClipboardProps,
} from "~/components/copy-table-to-clipboard";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { ChevronRightIcon } from "~/components/icons";

type Props = {};

export function ARList({}: Props) {
  const { actuals, codeWidth, containerRef, singleDisplay } =
    useAffectedResources();

  if (!actuals?.length) {
    return <Box />;
  }

  return (
    <Fragment>
      <AffectedResourcesHeader />
      <Box mt={4}>
        {actuals.map((result, index) => {
          if (!result.actual) return null;
          const defaultExpanded = Boolean(result.numResources === 1);

          const fields = result.actual[index]?.fields;
          const columns = fields?.map((field) =>
            field?.name ? field.name : "",
          );
          const rows = result.actual.map((actual) => {
            return (
              actual.fields?.flatMap((field) =>
                field?.value ? JSON.parse(field?.value) : null,
              ) || []
            );
          });

          let tableData: CopyTableToClipboardProps["tableData"] | null = null;
          if (columns && rows) {
            tableData = {
              details: null,
              columns,
              rows,
            };
          }

          return (
            <Fragment>
              {tableData && (
                <CopyTableToClipboard
                  tableData={tableData}
                  sx={{ my: 1, textAlign: "right" }}
                />
              )}
              <TableContainer
                ref={containerRef}
                key={result.resourceName}
                component={Paper}
                sx={{ mb: 3 }}
              >
                {!singleDisplay ? (
                  <ListTable
                    result={result}
                    parentIndex={index}
                    defaultExpanded={defaultExpanded}
                    codeWidth={codeWidth}
                  />
                ) : (
                  <Accordion defaultExpanded={true}>
                    {!singleDisplay && (
                      <AccordionSummary
                        sx={{ backgroundColor: "background.lighter" }}
                      >
                        {result.resourceTitle}
                        <Typography
                          component="span"
                          color="text.secondary"
                          sx={{ pl: 1 }}
                        >
                          {result.numResources}
                        </Typography>
                      </AccordionSummary>
                    )}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListTable
                        result={result}
                        parentIndex={index}
                        defaultExpanded={defaultExpanded}
                        codeWidth={codeWidth}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
              </TableContainer>
            </Fragment>
          );
        })}
      </Box>
    </Fragment>
  );
}

const ListTable = ({
  result,
  parentIndex,
  defaultExpanded,
  codeWidth,
}: {
  result: PolicyQueryAssessmentResources;
  parentIndex: number;
  defaultExpanded: boolean;
  codeWidth: number | null;
}) => {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const fields = result.actual ? result.actual[parentIndex]?.fields : null;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "background.lighter",
              "& > *": {
                borderBottom: "unset",
                cursor: "pointer",
              },
            }}
          >
            {fields &&
              fields.map((field) => {
                return (
                  <TableCell key={field?.name}>
                    <TableSortLabel direction="desc">
                      {field?.name}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            <TableCell width="50px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.actual?.slice(pageItems.from, pageItems.to).map((actual) => {
            return (
              <ErrorBoundary
                key="arListCodeBlock"
                FallbackComponent={FallbackComponent}
              >
                <Row
                  actual={actual}
                  defaultOpen={defaultExpanded}
                  width={codeWidth}
                />
              </ErrorBoundary>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        totalCount={result?.actual?.length || 0}
        setPageItems={setPageItems}
      />
    </Fragment>
  );
};

function Row({
  actual,
  defaultOpen = false,
  width,
}: {
  actual: Resource;
  defaultOpen?: boolean;
  width: number | null;
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{
          "& > *": { borderBottom: "unset", cursor: "pointer" },
        }}
      >
        {actual.fields?.map((field) => {
          return (
            <TableCell key={field?.name} component="th" scope="row">
              {JSON.parse(field?.value)}
            </TableCell>
          );
        })}
        <TableCell align="right">
          <Tooltip title="expand" placement="top">
            <IconButton onClick={() => setOpen(!open)}>
              <ChevronRightIcon
                sx={{
                  transition: "transform 0.3s ease",
                  transform: open ? `rotate(90deg)` : "rotate(0deg)",
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {actual.assessmentField && (
        <TableRow>
          <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                ".hljs-deletion": {
                  display: "inline-block",
                  width: "100%",
                  color: theme.palette.error.light,
                  background: alpha(theme.palette.error.light, 0.1),
                },
                ".hljs-addition": {
                  display: "inline-block",
                  width: "100%",
                  color: theme.palette.none.light,
                  background: alpha(theme.palette.none.light, 0.1),
                },
              }}
            >
              <Code className="diff" style={{ width: width ?? "unset" }}>
                {JSON.parse(actual.assessmentField.value)}
              </Code>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}

const FallbackComponent = ({ error }: { error: Error }) => {
  return (
    <TableRow>
      <TableCell colSpan={4}>
        <Flex center sx={{ px: 2, py: 3 }}>
          <Box
            textAlign="center"
            sx={{ width: "90%", maxWidth: 600, minWidth: 300 }}
          >
            <Typography sx={{ pb: 3 }}>
              Sorry about that, we're still working on this feature. This is
              likely a parsing issue, and should be fixed very soon.
            </Typography>
            <Typography sx={{ pb: 3, color: "error.main" }}>
              {error?.message}
            </Typography>
          </Box>
        </Flex>
      </TableCell>
    </TableRow>
  );
};
