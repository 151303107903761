import { Space } from "~/lib/types";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";

type AssetContextualLinkProps = {
  type: (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType];
  space: Space;
  assetId: string;
  contextId: string;
};

export const useAssetContextualLink = ({
  type,
  space,
  assetId,
  contextId,
}: AssetContextualLinkProps) => {
  const assetPathByType = {
    [AssetContextualLinkType.Cve]: "vulnerabilities",
    [AssetContextualLinkType.Advisory]: "advisories",
    [AssetContextualLinkType.Check]: "checks",
    [AssetContextualLinkType.Software]: "software",
  };

  const contextUrl = `/space/inventory/${assetId}/${assetPathByType[type] || ""}?spaceId=${space.id}&queryTerms=${contextId}`;

  return { contextUrl };
};
