import { IntegrationType, IntegrationTypeSummary } from "~/operations";
// temporary icons
import { getTemporaryIcon } from "~/images/icons/temporary-icons";
import { AzureColorIcon } from "~/components/icons/mondoo/azure-color";
import { GCPColorIcon } from "~/components/icons/mondoo/gcp-color";
import { MS365Icon } from "~/components/icons/mondoo/ms365-color";
import { AwsColorIcon } from "~/components/icons/mondoo/aws-color";
import { KubernetesIcon } from "~/components/icons/mondoo/kubernetes";
import { Box } from "@mui/material";

export const mapIntegrationType = (type: string) => {
  switch (type.toLowerCase()) {
    case "aws":
      return "aws";
    case "aws_s3":
      return "amazons3";
    case "s3":
      return "amazon-s3-compatible";
    case "k8s":
      return "kubernetes";
    case "managed_client":
      return "managed";
    case "microsoft_defender":
      return "defender";
    case "ticket_system_jira":
      return "ticket_system_jira";
    case "ticket_system_email":
      return "ticket_system_email";
    case "ticket_system_zendesk":
      return "ticket_system_zendesk";
    default:
      return type.toLowerCase();
  }
};

// TODO: this should be returned from the server
export const formatIntegrationName = (type: string) => {
  switch (type.toLowerCase()) {
    case "aws":
      return "AWS";
    case "azure":
      return "Azure";
    case "azure_blob":
      return "Azure Blob Storage";
    case "bigquery":
      return "BigQuery";
    case "snowflake":
      return "Snowflake";
    case "aws_s3":
      return "Amazon S3";
    case "s3":
      return "S3 Compatible Services";
    case "gcp":
      return "Google Cloud Platform";
    case "gcs_bucket":
      return "Google Cloud Storage Bucket";
    case "gitlab":
      return "GitLab";
    case "github":
      return "GitHub";
    case "host":
      return "Domain/IP Address";
    case "k8s":
      return "Kubernetes";
    case "ms365":
      return "Microsoft 365";
    case "okta":
      return "Okta";
    case "oci":
      return "Oracle Cloud Infrastructure";
    case "google_workspace":
      return "Google Workspace";
    case "managed_client":
      return "Managed Clients";
    case "microsoft_defender":
      return "Microsoft Defender";
    case "postgres":
      return "PostgreSQL";
    case "hosted_slack":
      return "Slack";
    case "slack":
      return "Slack Notifications";
    case "msteams":
      return "Microsoft Teams Notifications";
    case "telegram":
      return "Telegram Notifications";
    case "httppost":
      return "HTTP Webhook Notifications";
    case "ticket_system_jira":
      return "Atlassian Jira";
    case "ticket_system_email":
      return "Ticketing via email";
    case "ticket_system_zendesk":
      return "Zendesk";
    default:
      return type.toLowerCase();
  }
};

export const chatIntegrations = ["msteams", "slack", "telegram", "httppost"];

export const isChat = (type: string) =>
  chatIntegrations.some((x) => x === type.toLowerCase());

export const getTagline = (type: string) => {
  if (isChat(type)) return "Chat Ops";

  switch (type.toLowerCase()) {
    case "aws":
    case "azure":
    case "gcp":
    case "oci":
      return "Cloud Hosting";
    case "github":
    case "gitlab":
    case "google_workspace":
    case "hosted_slack":
    case "ms365":
    case "okta":
      return "SaaS Platform";
    default:
      return "";
  }
};

export const getProviderIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case "aws":
      return <AwsColorIcon sx={{ fontSize: 48 }} />;
    case "azure":
      return <AzureColorIcon sx={{ fontSize: 48 }} />;
    case "gcp":
      return <GCPColorIcon sx={{ fontSize: 48 }} />;
    case "k8s":
      return <KubernetesIcon sx={{ fontSize: 48 }} />;
    case "ms365":
      return <MS365Icon sx={{ fontSize: 48 }} />;
    default:
      return (
        <Box
          component="img"
          src={getTemporaryIcon(type)}
          sx={{ width: 48, height: 48 }}
        />
      );
  }
};

export const integrationTypes = [
  IntegrationType.Httppost,
  IntegrationType.K8S,
  IntegrationType.ManagedClient,
  IntegrationType.Msteams,
  IntegrationType.Slack,
  IntegrationType.Telegram,
  IntegrationType.Azure,
  IntegrationType.AzureBlob,
  IntegrationType.Gcp,
  IntegrationType.Ms365,
  IntegrationType.MicrosoftDefender,
  IntegrationType.Okta,
  IntegrationType.GoogleWorkspace,
  IntegrationType.Bigquery,
  IntegrationType.Snowflake,
  IntegrationType.S3,
  IntegrationType.AwsS3,
  IntegrationType.Gitlab,
  IntegrationType.Github,
  IntegrationType.HostedSlack,
  IntegrationType.GcsBucket,
  IntegrationType.Postgres,
  IntegrationType.TicketSystemJira,
  IntegrationType.TicketSystemEmail,
  IntegrationType.TicketSystemZendesk,
  IntegrationType.Oci,
  IntegrationType.Host,
];

export const activeSummaries = (
  summaries?: IntegrationTypeSummary[] | null,
) => {
  const hasIntegrations = (v: IntegrationTypeSummary) => v.totalCount > 0;

  const active =
    summaries?.filter(
      (v) => integrationTypes.includes(v.type) && hasIntegrations(v),
    ) || [];

  // Aws and AwsHosted are separate integration types
  // but are presented as a single integration type 😩
  const awsSummaries =
    summaries?.filter((x) =>
      [IntegrationType.Aws, IntegrationType.AwsHosted].includes(x.type),
    ) || [];

  const awsSummary = awsSummaries.reduce(
    (acc, curr) => {
      acc.activeCount += curr.activeCount;
      acc.assetsCount += curr.assetsCount;
      acc.checksCount += curr.checksCount;
      acc.failedCount += curr.failedCount;
      acc.warningCount += curr.warningCount;
      acc.policiesCount += curr.policiesCount;
      acc.totalCount += curr.totalCount;
      return acc;
    },
    {
      __typename: "IntegrationTypeSummary",
      type: IntegrationType.Aws,
      activeCount: 0,
      assetsCount: 0,
      checksCount: 0,
      failedCount: 0,
      warningCount: 0,
      policiesCount: 0,
      totalCount: 0,
    },
  );

  if (hasIntegrations(awsSummary)) {
    active.unshift(awsSummary);
  }

  return active;
};
