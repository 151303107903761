import React from "react";
import { Command } from "~/components/guides/components";
import { Box, Button, Divider, useTheme } from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Control } from "react-hook-form";
import { ZendeskFormInput } from "~/pages/integrations/hosted-integrations/forms/zendesk/types";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";

type JiraFormStep2Props = {
  control: Control<ZendeskFormInput>;
  isValid: boolean;
  isUpdateFlow: boolean;
};

export const ZendeskFormStep2 = ({
  control,
  isValid,
  isUpdateFlow,
}: JiraFormStep2Props) => {
  const theme = useTheme();

  return (
    <>
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Configure preferences
        </Command>
        <Box mt={3}>
          <AutoConfigurationSection
            control={control}
            showAutoCreateSection={true}
          />
        </Box>
      </Box>
      <Box width={1}>
        <Divider />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Button
          sx={{ textTransform: "uppercase" }}
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          Finalize {isUpdateFlow ? "Update" : "Setup"}
        </Button>
      </Box>
    </>
  );
};
