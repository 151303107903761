import React from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { useColorMode } from "~/providers/color-mode";
import { FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
};

export const CreateCaseZendeskModal = ({
  register,
  formState,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  const { mode } = useColorMode();

  return (
    <>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Subject
        </Typography>
        <TextField
          placeholder="Write a short description to identify the issue..."
          {...register("zendesk.title", { required: true })}
          error={Boolean(formState.errors?.email?.title)}
          helperText={formState.errors?.email?.title?.message}
          disabled={!hasCreateCasePermissions}
          InputProps={{
            sx: {
              backgroundColor:
                mode === "light" ? "common.white" : "common.black",
              borderRadius: 1,
            },
          }}
          data-test-id="case-title-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Body
          <Typography component="span" color="text.secondary">
            {" "}
            (generated and maintained by Mondoo)
          </Typography>
        </Typography>
        <TextField
          placeholder="Describe the issue..."
          multiline
          rows={6}
          {...register("zendesk.content", { required: true })}
          error={Boolean(formState.errors?.email?.content)}
          helperText={formState.errors?.email?.content?.message}
          disabled
          InputProps={{
            sx: {
              borderRadius: 1,
            },
          }}
          data-test-id="case-description-input"
        />
      </FormControl>
    </>
  );
};
