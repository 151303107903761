import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { EmailFormInput } from "~/pages/integrations/hosted-integrations/forms/email/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/email/AutoConfigurationSection";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  isRecipientsEmpty: boolean;
  recipients: { email: string; name: string }[] | null | undefined;
  space: Space;
};

export const CaseSettingsEmailIntegration = ({
  isRecipientsEmpty,
  recipients,
  integration,
  space,
}: CaseSettingsIntegrationProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm<EmailFormInput>({
    defaultValues: {
      autoCreateCases: false,
      defaultRecipient: "",
    },
    mode: "onChange",
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: EmailFormInput) => {
    if (
      integration?.configurationOptions?.__typename !==
      "EmailConfigurationOptions"
    )
      return;

    const defaultIntegrationName = data.defaultRecipient;

    const emailConfigurationOptions = {
      autoCreateTickets: data.autoCreateCases,
      recipients: data.recipients.map((recipient) => ({
        ...recipient,
        isDefault:
          data.recipients.length === 1 ||
          recipient.name === defaultIntegrationName,
      })),
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemEmail,
          configurationOptions: {
            emailConfigurationOptions,
          },
        },
      },
    });
  };

  useEffect(() => {
    // TypeScript users
    const subscription = watch(() => {
      isInitialized && handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, isInitialized]);

  const [watchAutoCreate] = watch(["autoCreateCases"]);

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "EmailConfigurationOptions") return;

    reset({
      recipients: configOptions?.recipients,
      autoCreateCases: configOptions?.autoCreateTickets,
      defaultRecipient:
        configOptions?.recipients.find((r) => r.isDefault)?.name || "",
    });

    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          disabled={!watchAutoCreate}
          isRecipientsEmpty={isRecipientsEmpty}
          recipients={recipients}
          showDefaultRecipientDropdown={true}
        />
      </Paper>
    </Box>
  );
};
