import { Box, Link, SxProps, Theme } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug";
import { Code } from "~/components/code";

export type MarkdownProps = {
  source: string;
  copyButton?: boolean;
  dataId?: string;
  className?: string;
  sx?: SxProps<Theme>;
};

export function Markdown({
  source,
  copyButton = true,
  dataId,
  className,
  sx,
}: MarkdownProps) {
  return (
    <Box
      className={className}
      data-id={dataId}
      data-testid="markdown"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <ReactMarkdown
        rehypePlugins={[
          // Adds `id` anchors to headings
          rehypeSlug,
        ]}
        components={{
          // Render code blocks with Code component
          pre({ children }) {
            return <Code copyButton={copyButton}>{children}</Code>;
          },
          // Render links with MUI Link component
          a({ children, href }) {
            return (
              <Link href={href} sx={{ textDecoration: "underline" }}>
                {children}
              </Link>
            );
          },
        }}
      >
        {source}
      </ReactMarkdown>
    </Box>
  );
}
