import React, { ReactNode } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { useColorMode } from "~/providers/color-mode";
import { FormattedRecipient } from "~/pages/integrations/hosted-integrations/forms/email/FormattedRecipient";
import { Control, FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  recipients: { email: string; name: string }[] | null | undefined;
  isRecipientsEmpty: boolean;
};

export const CreateCaseEmailModal = ({
  register,
  control,
  formState,
  recipients,
  isRecipientsEmpty,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  const { mode } = useColorMode();

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Recipient
        </Typography>

        <Controller
          name="email.recipient"
          {...{ control }}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              disabled={!hasCreateCasePermissions}
              sx={{
                "&.MuiInputBase-root": {
                  bgcolor: "code.background",
                },
              }}
              displayEmpty
              renderValue={(selected: ReactNode) => {
                const selectedRecipient = recipients?.find(
                  (recipient) => recipient.email === selected,
                );

                if (!selectedRecipient) {
                  return <>Select a recipient</>;
                }

                const selectedRecipientTitle = (
                  <FormattedRecipient
                    name={selectedRecipient.name}
                    email={selectedRecipient.email}
                  />
                );

                if (isRecipientsEmpty) {
                  return (
                    <>
                      No recipients available – create one in email integration
                      first
                    </>
                  );
                }

                if (!selected || !selectedRecipientTitle) {
                  return <>Select a recipient</>;
                }

                return <>{selectedRecipientTitle}</>;
              }}
            >
              {(recipients || []).map((recipient) => (
                <MenuItem key={recipient.name} value={recipient.email}>
                  {recipient.name} ({recipient.email})
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Summary
        </Typography>
        <TextField
          placeholder="Write a short description to identify the issue..."
          {...register("email.title", { required: true })}
          error={Boolean(formState.errors?.email?.title)}
          helperText={formState.errors?.email?.title?.message}
          disabled={!hasCreateCasePermissions}
          InputProps={{
            sx: {
              backgroundColor:
                mode === "light" ? "common.white" : "common.black",
              borderRadius: 1,
            },
          }}
          data-test-id="case-title-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Email content
          <Typography component="span" color="text.secondary">
            {" "}
            (generated and maintained by Mondoo)
          </Typography>
        </Typography>
        <TextField
          placeholder="Describe the issue..."
          multiline
          rows={6}
          {...register("email.content", { required: true })}
          error={Boolean(formState.errors?.email?.content)}
          helperText={formState.errors?.email?.content?.message}
          disabled
          InputProps={{
            sx: {
              borderRadius: 1,
            },
          }}
          data-test-id="case-description-input"
        />
      </FormControl>
      {/*<FormControl fullWidth sx={{ marginTop: 3 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: 1, fontWeight: 700 }}
            >
              Labels{" "}
              <Typography component="span" color="text.secondary">
                (Optional)
              </Typography>
            </Typography>
            <TextField
              placeholder="Add labels separated by commas..."
              {...register("labels")}
              error={Boolean(formState.errors.labels)}
              helperText={formState.errors.labels?.message}
              InputProps={{
                sx: {
                  backgroundColor:
                    mode === "light" ? "common.white" : "common.black",
                  borderRadius: 1,
                },
              }}
              data-test-id="justification-input"
            />
          </FormControl>*/}
    </>
  );
};
