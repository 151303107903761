import React, { ReactNode } from "react";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import { ToggleOption } from "~/pages/integrations/components/ToggleOption/ToggleOption";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import { SubField } from "~/components/Form/components";
import { Control } from "react-hook-form/dist/types/form";
import { EmailFormInput } from "~/pages/integrations/hosted-integrations/forms/email/types";
import { FormattedRecipient } from "./FormattedRecipient";

type AutoConfigurationSectionParams = {
  control: Control<EmailFormInput>;
  disabled: boolean;
  isRecipientsEmpty: boolean;
  recipients: { email: string; name: string }[] | null | undefined;
  showDefaultRecipientDropdown: boolean;
};

export const AutoConfigurationSection = ({
  control,
  disabled,
  recipients,
  isRecipientsEmpty,
  showDefaultRecipientDropdown,
}: AutoConfigurationSectionParams) => {
  return (
    <>
      <Grid item xs={12}>
        <ToggleOption
          title="Send drift email to this recipient"
          description="If 'Automatically create cases on drift' is enabled in space settings, send email to the selected recipient for each new drift case."
          formOptions={{
            name: "autoCreateCases",
            control,
          }}
        />
        {showDefaultRecipientDropdown && (
          <Box
            component={motion.div}
            initial={false}
            transition={{
              duration: 0.35,
              staggerChildren: 0.25,
            }}
            sx={{ overflow: "hidden" }}
          >
            <Controller
              name="defaultRecipient"
              {...{ control }}
              render={({ field }) => (
                <SubField
                  configuration="Select a drift destination"
                  caption="Select where to send email for each new drift case."
                  disabled={disabled}
                  field={
                    <Grid item xs={12} md={6} sx={{ alignSelf: "flex-end" }}>
                      <Select
                        {...field}
                        fullWidth
                        disabled={disabled}
                        sx={{
                          "&.MuiInputBase-root": {
                            bgcolor: "code.background",
                          },
                        }}
                        displayEmpty
                        renderValue={(selected: ReactNode) => {
                          const selectedRecipient = recipients?.find(
                            (recipient) => recipient.name === selected,
                          );

                          if (!selectedRecipient) {
                            return <>Select a recipient</>;
                          }

                          const selectedRecipientTitle = (
                            <FormattedRecipient
                              name={selectedRecipient.name}
                              email={selectedRecipient.email}
                            />
                          );

                          if (isRecipientsEmpty) {
                            return (
                              <>
                                No recipients available – create one in email
                                integration first
                              </>
                            );
                          }

                          if (!selected || !selectedRecipientTitle) {
                            return <>Select a recipient</>;
                          }

                          return <>{selectedRecipientTitle}</>;
                        }}
                      >
                        {(recipients || []).map((recipient) => (
                          <MenuItem key={recipient.name} value={recipient.name}>
                            {recipient.name} ({recipient.email})
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  }
                />
              )}
            />
          </Box>
        )}
      </Grid>
    </>
  );
};
