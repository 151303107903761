import React, { useRef, useState } from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { useColorMode } from "~/providers/color-mode";
import { JiraProject } from "~/operations";
import {
  Autocomplete,
  DropdownAutocomplete,
} from "~/components/Form/components";
import { Control, FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  isProjectsEmpty: boolean;
  projects: JiraProject[] | null | undefined;
  saveSelectedProject: (value: string) => void;
  fetchProjects: (value: string) => void;
};

export const CreateCaseJiraModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  projects,
  isProjectsEmpty,
  saveSelectedProject,
  fetchProjects,
}: CreateCaseModalProps) => {
  const { mode } = useColorMode();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [projectQuery, setProjectQuery] = useState("");

  const handleToggle = () => {
    setIsAutocompleteOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Project
        </Typography>

        <Controller
          key="projectId"
          name="jira.projectId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const getDisplayValue = () => {
              const selectedProject = projects?.find(
                (project) => project.key === field.value,
              );
              const selectedProjectTitle = `(${selectedProject?.key}) ${selectedProject?.name}`;

              if (projectQuery.length > 0 && isProjectsEmpty) {
                return <>No projects match the search criteria</>;
              }

              if (isProjectsEmpty) {
                return <>No projects available – create one in Jira first</>;
              }

              if (!field.value || !selectedProjectTitle) {
                return <>Select a project</>;
              }

              return <>{selectedProjectTitle}</>;
            };

            return (
              <>
                <DropdownAutocomplete
                  open={isAutocompleteOpen}
                  anchorRef={anchorRef}
                  selected={getDisplayValue()}
                  onToggle={handleToggle}
                  data-testid="project-dropdown"
                />
                <Autocomplete
                  options={(projects || []).map(({ key, name }) => ({
                    value: key,
                    label: name,
                  }))}
                  scope="projects"
                  selected={field.value}
                  onSelect={(option) => {
                    saveSelectedProject(option);
                    field.onChange(option);
                    setIsAutocompleteOpen(false);
                  }}
                  open={isAutocompleteOpen}
                  onClose={() => setIsAutocompleteOpen(false)}
                  anchorRef={anchorRef}
                  onSearchInputChange={(e) => {
                    setProjectQuery(e.target.value);
                    fetchProjects(e.target.value);
                  }}
                />
              </>
            );
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Summary
        </Typography>
        <TextField
          placeholder="Write a short description to identify the issue..."
          {...register("jira.title", { required: true })}
          error={Boolean(formState.errors?.jira?.title)}
          helperText={formState.errors?.jira?.title?.message}
          disabled={!hasCreateCasePermissions}
          InputProps={{
            sx: {
              backgroundColor:
                mode === "light" ? "common.white" : "common.black",
              borderRadius: 1,
            },
          }}
          data-test-id="case-title-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Description
          <Typography component="span" color="text.secondary">
            {" "}
            (generated and maintained by Mondoo)
          </Typography>
        </Typography>
        <TextField
          placeholder="Describe the issue..."
          multiline
          rows={6}
          {...register("jira.description", { required: true })}
          error={Boolean(formState.errors?.jira?.description)}
          helperText={formState.errors?.jira?.description?.message}
          disabled
          InputProps={{
            sx: {
              borderRadius: 1,
            },
          }}
          data-test-id="case-description-input"
        />
      </FormControl>
      {/*<FormControl fullWidth sx={{ marginTop: 3 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: 1, fontWeight: 700 }}
            >
              Labels{" "}
              <Typography component="span" color="text.secondary">
                (Optional)
              </Typography>
            </Typography>
            <TextField
              placeholder="Add labels separated by commas..."
              {...register("labels")}
              error={Boolean(formState.errors.labels)}
              helperText={formState.errors.labels?.message}
              InputProps={{
                sx: {
                  backgroundColor:
                    mode === "light" ? "common.white" : "common.black",
                  borderRadius: 1,
                },
              }}
              data-test-id="justification-input"
            />
          </FormControl>*/}
    </>
  );
};
