import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Space } from "~/lib/types";
import { LoadingFailedPage, LoadingPage } from "../components/loading";
import { FleetDashboardCard } from "../components/dashboard-cards/fleet";
import { IntegrationsDashboardCard } from "../components/dashboard-cards/integrations";
import {
  IntegrationType,
  LoadSpaceStatsQuery,
  TestIamActionsQuery,
  useLoadSpaceStatsQuery,
} from "~/operations";
import { AwsGuide } from "./aws-guide";
import { VastEmptyness } from "./vast-emptyness/vast-emptyness";
import { TopVulnerabilityCard } from "./dashboard/vulnerabilities/top-vulnerability-card";
import { Overview } from "./space/overview";

type SpaceStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>;

export const assetFilterUrl = (
  severity?: string[],
  platform?: string[],
  integrationMrn?: string,
): string => {
  const url = new URL(window.location.toString());
  const params = new URLSearchParams(url.search);

  if (severity?.length) {
    params.set("severity", severity.join("%2C"));
  }

  if (platform?.length) {
    params.set("platform", platform.join("%2C"));
  }

  if (integrationMrn) {
    const queryterms = JSON.stringify({
      "mondoo.com/integration-mrn": integrationMrn,
    });
    params.set("queryTerms", queryterms);
  }

  return `/space/inventory?${params}`;
};

export type DashboardContentProps = {
  space: Space;
  spaceStats: SpaceStats;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function DashboardContent({
  space,
  spaceStats,
  availablePermissions,
}: DashboardContentProps) {
  const noData = spaceStats.riskdistribution.total === 0;

  if (noData) {
    const VastEmptynessOptions = {
      id: "overview",
      headline: "WELCOME TO MONDOO",
      tagline:
        "To begin scanning for vulnerabilities, let's integrate your infrastructure with Mondoo.",
      buttonText: "Start Scanning",
      href: `/space/integrations/add/mondoo/setup?spaceId=${space.id}`,
      buttonText2: "Browse Integrations",
      hrefButton2: `/space/integrations/add?spaceId=${space.id}`,
    };

    return <VastEmptyness options={VastEmptynessOptions} />;
  }

  return <Overview space={space} />;
}

type SpaceDashboardProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SpaceDashboard({
  space,
  availablePermissions,
}: SpaceDashboardProps) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(searchParams.get("guide") === "aws"));
  }, []);

  const { loading, data, error } = useLoadSpaceStatsQuery({
    variables: { mrn: space.mrn },
    notifyOnNetworkStatusChange: false,
  });

  if (loading) {
    return <LoadingPage what="Space Stats" />;
  }

  if (error || !data?.space?.stats) {
    return <LoadingFailedPage what="Space Stats" />;
  }

  const handleClose = () => {
    // remove the guide=aws search param upon closing
    searchParams.delete("guide");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const stats = data.space.stats;

  document.title = `${space.name} · Mondoo`;

  return (
    <Fragment>
      <DashboardContent
        space={space}
        spaceStats={stats}
        availablePermissions={availablePermissions}
      />
      <AwsGuide space={space} open={open} onClose={handleClose} />
    </Fragment>
  );
}

const demoStats: SpaceStats = {
  riskdistribution: {
    total: 20,
    Error: 0,
    A: 9,
    B: 3,
    C: 3,
    D: 5,
    F: 0,
    U: 0,
    __typename: "RiskDistribution",
  },
  integrationsStatistics: [
    {
      active: 1,
      errored: 0,
      pending: 1,
      total: 2,
      type: IntegrationType.K8S,
      __typename: "IntegrationStatistics",
    },
  ],
  assetGroupStatistics: [],
  __typename: "SpaceStatistics",
};
