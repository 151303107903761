import React from "react";
import { Grid, Typography } from "@mui/material";
import { Impact } from "~/components/impact";
import { FirewatchTooltip } from "~/components/FirewatchRow/Tooltip";
import { BlastRadius } from "~/components/blast/blast-radius";
import { BlastRadiusFieldsFragment } from "~/operations";
import { useImpact } from "~/components/impact/use-impact";

type CheckScoreBlastRadiusProps = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  isActive?: boolean;
};

export const ScoreBlastRadius = ({
  blastRadius,
  isActive = true,
}: CheckScoreBlastRadiusProps) => {
  const { __typename, indicator, assets, affected, ...ratings } =
    blastRadius || {};
  // color should be same as impact
  const { color } = useImpact({
    impact: 85,
    isActive: isActive,
    isRiskScore: true,
  });

  return (
    <BlastRadius
      radius={indicator || "none"}
      tooltip={<FirewatchTooltip data={ratings} />}
      color={color}
    />
  );
};
