import { useEffect, useMemo, useState } from "react";
import { Space } from "~/lib/types";
import {
  VulnerabilityStats,
  useLoadVulnDashboardStatsLazyQuery,
} from "~/operations";
import { StatType } from "./types";

type UseSmallDonutProps = {
  space: Space;
  /**
   * The type of data we should pull out of the VulnerabilityStats API
   * options are "ASSET", "CVE", or "ADVISORY"
   */
  type: StatType;
};

export function useSmallDonut({ space, type }: UseSmallDonutProps) {
  const [rawData, setRawData] = useState<VulnerabilityStats["counts"]>([]);
  const [loadDashStats, { loading, error, data }] =
    useLoadVulnDashboardStatsLazyQuery({
      variables: {
        input: {
          spaceMrn: space.mrn,
        },
      },
    });

  useEffect(() => {
    loadDashStats();
  }, [space]);

  useEffect(() => {
    // package data
    if (data) {
      const d = data.vulnDashboardStats?.stats?.find(
        (e) => e?.statType === type,
      )?.counts;
      setRawData(d);
    }
  }, [data]);

  const packageData = useMemo(() => {
    const low = rawData?.find((e) => e?.severity === "LOW")?.count || 0;
    const medium = rawData?.find((e) => e?.severity === "MEDIUM")?.count || 0;
    const high = rawData?.find((e) => e?.severity === "HIGH")?.count || 0;
    const critical =
      rawData?.find((e) => e?.severity === "CRITICAL")?.count || 0;
    const total = rawData?.find((e) => e?.severity === "total")?.count || 0;

    //add up the low, medium, high, critical
    const totalVuln = low + medium + high + critical;
    // if we subtract total from totalVuln we get the none, but only if the outcome is not a negative number
    const none = total - totalVuln > 0 ? total - totalVuln : 0;

    return [
      { impact: "low", value: low || 0 },
      { impact: "medium", value: medium || 0 },
      { impact: "high", value: high || 0 },
      { impact: "critical", value: critical || 0 },
      { impact: "none", value: none || 0 },
    ];
  }, [rawData]);

  const total = useMemo(() => {
    return rawData?.find((e) => e?.severity === "total")?.count || 0;
  }, [rawData]);

  return {
    loading,
    chartData: {
      context: packageData,
      total,
    },
  };
}
