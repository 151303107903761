import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  SyntheticEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { get, map, omit, pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  Obj,
  PAGE,
  Route,
  Email,
  ListName,
  TabOption,
  VariantIcon,
  FilterOption,
  ComfyListIcon,
  getPageIndexes,
  PaginationOptions,
  rowsPerPageOptions,
  PolicyIndicatorProps,
} from "~/components/ui-library";
import {
  Maybe,
  Mquery,
  Policy,
  MProperty,
  PolicyGroup,
  PolicyInput,
  PolicyAction,
  ContentPlatform,
  PolicyStatistics,
  useQueryPackLazyQuery,
  useAssignPolicyMutation,
  useDeletePolicyMutation,
  PolicyMutationStatistics,
  useUnassignPolicyMutation,
  useGetPolicyPropsLazyQuery,
  useRegistryPolicyLazyQuery,
  RegistryPolicyDocument,
  SearchPolicyDocument,
} from "~/operations";
import { Space } from "~/lib/types";
import { useParams } from "~/hooks";
import {
  getPolicyMrn,
  updateQueryString,
  getPolicyIndicatorState,
} from "~/utils";
import { CategoryIcon } from "~/components/icons";

// ToDo: Import `QueryPackInput` from `~/operations`, once available
type QueryPackInput = PolicyInput;
// ToDo: Import `QueryPackGroup` from `~/operations`, once available
type QueryPackGroup = PolicyGroup;

// Facets
export enum RegistryType {
  Checks = "CHECKS",
  Properties = "PROPERTIES",
}
export enum ViewType {
  Group = "group",
  List = "list",
}
enum Impact {
  All = "ALL",
  Critical = "CRITICAL",
  Medium = "MEDIUM",
  High = "HIGH",
}
type Facets = (PolicyInput | QueryPackInput) & {
  query: string;
  namespace: string;
  uid: string;
  impact: Impact;
  platforms: string[];
  variant: string;
  registryType: RegistryType;
  viewType: ViewType;
};
const defaultFacets: Facets = {
  query: "",
  namespace: "",
  uid: "",
  impact: Impact.All,
  platforms: [],
  variant: "",
  registryType: RegistryType.Checks,
  viewType: ViewType.Group,
};

// State
const defaultRegistryTabOptions = [
  {
    label: "Checks",
    value: RegistryType.Checks,
  },
  {
    label: "Properties",
    value: RegistryType.Properties,
  },
];

const defaultVariants: TabOption[] = [
  {
    label: "Overall",
    value: "",
    icon: "",
  },
];

const defaultPlatforms: Record<"name" | "value", string>[] = [
  {
    name: "overall",
    value: "overall",
  },
];

const viewOptions: TabOption[] = [
  { value: ViewType.Group, label: <CategoryIcon /> },
  { value: ViewType.List, label: <ComfyListIcon /> },
];

enum Filters {
  Impact = "impact",
  Platforms = "platforms",
}
const defaultFilterOptions: FilterOption[] = [
  {
    name: Filters.Impact,
    label: "Impact",
    options: Object.values(Impact),
    value: defaultFacets.impact,
    multiple: false,
  },
  {
    name: Filters.Platforms,
    label: "Platforms",
    // options will be populated from query
    options: [],
    value: defaultFacets.platforms,
    multiple: true,
  },
];

type State = {
  isReady: boolean;
  isQuerypack: boolean;
  policyIndicator: PolicyIndicatorProps["loading"];
  isProperties: boolean;
  isListView: boolean;
  page: number;
  rowsPerPage: number;
  from: number;
  to: number;
  paginationText: string;
  activeStatsText: string;
  deleteModalOpen: boolean;
};

const defaultState: State = {
  isReady: false,
  isQuerypack: false,
  policyIndicator: null,
  isProperties: false,
  isListView: false,
  page: PAGE,
  rowsPerPage: rowsPerPageOptions[0],
  ...getPageIndexes({
    page: PAGE,
    rowsPerPage: rowsPerPageOptions[0],
  }),
  paginationText: "Showing 1-10 Checks of 0",
  activeStatsText: "0/0 checks enabled",
  deleteModalOpen: false,
};

type MqueryType = Mquery & {
  namespace: string;
};

type RegistryEntry = {
  name: string;
  namespace: string;
  mrn: string;
  uid: string;
  assigned: boolean;
  policyMrn: string;
  trustLevel: string;
  authors: ListName[];
  version: string;
  certifiedBy: ListName;
  githubUrl: string;
  docs: string | undefined;
  groups: (PolicyGroup | QueryPackGroup)[];
  queries: MqueryType[];
  variants: TabOption[];
  properties: MProperty[];
  platforms: ContentPlatform[];
  cliCommand: string;
  installDocsURL: string;
  emailLink: string;
  statistics: PolicyStatistics;
  mutationStatistics: PolicyMutationStatistics;
  action?: Maybe<PolicyAction>;
};

export type UseRegistryReturnProps = {
  loading: boolean;
  error: ApolloError | undefined;
  filtersLoading: boolean;
  filtersErrors: string[] | undefined;
  filterOptions: FilterOption[];
  registryTabOptions: TabOption[];
  viewOptions: TabOption[];
  registryByPlatform: Record<"name" | "value", string>[];
  registryEntry: RegistryEntry;
  paginatedGroups: (PolicyGroup | QueryPackGroup)[];
  facets: Facets;
  state: State;
  filters: Obj;
  adjustedProps: MProperty[];
  onNavigate: (href: Route["href"]) => void;
  onFacetsChange: (value: Partial<Facets>) => void;
  onStateChange: (value: Partial<State>) => void;
  onChangeBreadcrumb: () => void;
  onTabChange: (value: string) => void;
  onVariantsChange: (value: string) => void;
  onPaginationChange: (value: PaginationOptions) => void;
  onNavigateToQueries: (value: MqueryType) => void;
  listPaginationText: string;
  onListPaginationChange: (value: PaginationOptions) => void;
  onChangeAssignHandler: () => Promise<any>;
  deletePolicyLoading: boolean;
  handleDeletePolicy: (policyMrn: string) => void;
  getPolicyIndicatorProps: () => PolicyIndicatorProps;
};

/**
 * API-Calls should happen only when `state.isReady` is `true`,
 *  as NextJS queryString not available on initialMount
 */
const useRegistry = ({
  isQuerypack,
  space,
}: {
  isQuerypack: boolean;
  space: Space;
}): UseRegistryReturnProps => {
  let navigate = useNavigate();
  const { router, facetsFromQueryString } = useParams(defaultFacets);
  const [facets, setFacets] = useState<Facets>(defaultFacets);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    ...defaultState,
    isQuerypack,
  });
  /**
   * adjustedProps holds a modified list of properties that combine the users modified props among the props,
   *  that belong to the policy that is currently being viewed.
   */
  const [adjustedProps, setAdjustedProps] = useState<MProperty[]>([]);
  // ToDo: Moving this to state is making `setState` calling repeatedly due to onChange handler of `QueriesTable`.
  const [listPaginationText, setListPaginationText] = useState(
    "Showing 1-10 Checks of 0",
  );

  const onNavigate = useCallback(
    (href: Route["href"]) => {
      router.navigateTo(href);
    },
    [router],
  );

  const onFacetsChange = useCallback((value: Partial<Facets>) => {
    setFacets((v) => ({ ...v, ...value }));
  }, []);

  const onStateChange = useCallback((value: Partial<State>) => {
    setState((v) => ({ ...v, ...value }));
  }, []);

  const onTabChange = useCallback((registryType: string) => {
    onFacetsChange({
      ...omit(defaultFacets, ["namespace", "uid"]),
      registryType: registryType as RegistryType,
    });
  }, []);

  const onVariantsChange = useCallback((variant: string) => {
    onFacetsChange({
      variant,
    });
  }, []);

  const onPaginationChange = useCallback(
    ({
      page = defaultState.page,
      rowsPerPage = defaultState.rowsPerPage,
      from = defaultState.from,
      to = defaultState.to,
      count = 0,
    }: PaginationOptions) => {
      onStateChange({
        page,
        rowsPerPage,
        from,
        to,
        paginationText: ``,
      });
    },
    [state.isProperties],
  );

  const onListPaginationChange = useCallback(
    ({ page, rowsPerPage, from, to, count }: PaginationOptions) => {
      if (from && to) {
        setListPaginationText(`Showing ${from}-${to} Checks of ${count}`);
      } else {
        setListPaginationText(``);
      }
    },
    [],
  );

  const [
    policyQuery,
    { loading: policyLoading, error: policyError, data: policyData },
  ] = useRegistryPolicyLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    querypackQuery,
    { loading: querypackLoading, error: querypackError, data: querypackData },
  ] = useQueryPackLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    getPolicyProps,
    { loading: propsLoading, error: propsError, data: propsData },
  ] = useGetPolicyPropsLazyQuery();

  const [assignPolicyMutation] = useAssignPolicyMutation({
    refetchQueries: [
      {
        query: RegistryPolicyDocument,
        variables: {
          input: {
            mrn: getPolicyMrn(facets),
            spaceMrn: space.mrn,
            Objvariant: facets.variant || undefined,
          },
        },
      },
    ],
  });

  const [unAssignPolicyMutation] = useUnassignPolicyMutation({
    refetchQueries: [
      {
        query: RegistryPolicyDocument,
        variables: {
          input: {
            mrn: getPolicyMrn(facets),
            spaceMrn: space.mrn,
            Objvariant: facets.variant || undefined,
          },
        },
      },
    ],
  });

  const [deletePolicyMutation, { loading: deletePolicyLoading }] =
    useDeletePolicyMutation({
      onCompleted: () => {
        enqueueSnackbar("Successfully deleted policy", { variant: "success" });
        onStateChange({
          deleteModalOpen: false,
        });
        navigate(`/space/registry?spaceId=${space.id}`);
      },
      onError: (err) => {
        enqueueSnackbar("Failed to delete policy: " + err.message, {
          variant: "error",
        });
      },
      refetchQueries: [
        {
          query: SearchPolicyDocument,
          variables: {
            input: omit(facets, ["namespace"]),
          },
        },
      ],
    });

  const handleDeletePolicy = useCallback(
    (policyMrn: string) => {
      deletePolicyMutation({ variables: { input: { policyMrn } } });
    },
    [deletePolicyMutation],
  );

  useEffect(() => {
    if (!facetsFromQueryString) {
      return;
    }

    onFacetsChange(facetsFromQueryString);
    onStateChange({
      isReady: true,
      isProperties:
        facetsFromQueryString.registryType === RegistryType.Properties,
      isListView: facetsFromQueryString.viewType === ViewType.List,
    });
  }, [facetsFromQueryString]);

  useEffect(() => {
    if (!state.isReady) {
      return;
    }

    updateQueryString({
      router,
      defaultValues: pick(defaultFacets, [
        "registryType",
        "viewType",
        "variant",
      ]),
      newValues: pick(facets, ["registryType", "viewType", "variant"]),
    });

    const isProperties = facets.registryType === RegistryType.Properties;
    const isListView = facets.viewType === ViewType.List;
    onStateChange({
      isProperties,
      isListView,
    });

    const registryQuery = state.isQuerypack ? querypackQuery : policyQuery;

    registryQuery({
      variables: {
        input: {
          mrn: getPolicyMrn(facets),
          spaceMrn: space.mrn,
          variantPlatformFilter: facets.variant || undefined,
        },
      },
    });
  }, [facets, state.isReady, state.policyIndicator]);

  useEffect(() => {
    getPolicyProps({
      variables: {
        input: {
          mrn: space.mrn,
        },
      },
    });
  }, [facets, state.isReady]);

  // In order to get the users actual saved adjusted props to view,
  // we need to check them against the standard props.  Here we take both
  // collections and we check to see if the MRN on any of the adjusted props
  // match any of the standard props.  If they do, we swap out the adjusted value
  useEffect(() => {
    const alteredProps = propsData?.policy?.properties || [];
    const standardProps: MProperty[] =
      policyData?.policy?.queries?.flatMap((q) => q.properties ?? []) || [];

    const propsCopy = JSON.parse(JSON.stringify(standardProps));

    alteredProps?.forEach((prop) =>
      replaceObjectByProperty(propsCopy, "mrn", prop.mrn, prop),
    );

    // Sort the props by the UID value
    propsCopy.sort((a: MProperty, b: MProperty) =>
      a.uid > b.uid ? 1 : b.uid > a.uid ? -1 : 0,
    );

    setAdjustedProps(propsCopy);
  }, [propsData?.policy?.properties, policyData?.policy]);

  const registryEntry: RegistryEntry = useMemo(() => {
    const registryEntry = state.isQuerypack
      ? querypackData?.policy
      : policyData?.policy;

    const mrn = registryEntry?.mrn || "";
    const groups = (registryEntry?.groups || []) as (
      | PolicyGroup
      | QueryPackGroup
    )[];
    const properties =
      policyData?.policy?.queries?.flatMap((q) => q.properties ?? []) || [];
    const queries = ((registryEntry?.queries || []) as MqueryType[])
      ?.filter(({ parent }) => (facets.variant ? true : parent == null))
      .map((query) => ({
        ...query,
        namespace:
          registryEntry?.trustLevel === "PRIVATE"
            ? space.id
            : query.namespace || "mondoohq",
        uid: (query.parent?.split("/")?.pop() ||
          query.uid ||
          query.mrn.split("/").pop()) as string,
      }));
    const variants = (
      (registryEntry as Policy)?.variantPlatformFilters?.map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      })) || []
    ).sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
    let href = "";
    try {
      href = window ? window.location.href : "";
    } catch (error) {
      console.error("window is not ready", error);
    }
    const email = Email.Share;
    const emailSubject = `Take a look at this ${
      state.isQuerypack ? "querypack" : "policy"
    }`;
    const emailBody = href;
    const emailLink = `mailto:${email}?subject=${encodeURIComponent(
      emailSubject,
    )}&body=${encodeURIComponent(emailBody)}`;
    const statistics = (registryEntry?.statistics || {
      checks: 0,
      policies: 0,
      queries: 0,
    }) as PolicyStatistics;
    const mutationStatistics = (registryEntry?.mutationStatistics || {
      checks: {
        active: 0,
        ignored: 0,
        removed: 0,
      },
      queries: { active: 0, ignored: 0, removed: 0 },
    }) as PolicyMutationStatistics;
    const statsActive = state.isQuerypack
      ? mutationStatistics.queries.active
      : mutationStatistics.checks.active;
    const statsTotal = state.isQuerypack
      ? statistics.queries
      : statistics.checks;
    const statsText = state.isQuerypack ? "queries" : "checks";

    // Reset pagination
    onPaginationChange({ count: groups.length });
    onListPaginationChange({ count: queries.length });
    onStateChange({
      activeStatsText: `${statsActive}/${statsTotal} ${statsText} enabled`,
    });

    // if there are variants, we don't want to show overall documentation
    // when looking at individual variants
    const docs = facets.variant !== "" ? "" : registryEntry?.docs;

    return {
      name: registryEntry?.name || "",
      mrn,
      action: (registryEntry as Policy)?.action,
      assigned: registryEntry?.assigned || false,
      // ToDo: `namespace` not available in the response.
      namespace: get(registryEntry, "namespace") || "",
      // ToDo: `uid` not available in the response.
      uid: registryEntry?.mrn.split("/").pop() || "",
      policyMrn: getPolicyMrn(facets),
      trustLevel: registryEntry?.trustLevel || "",
      authors: (registryEntry?.authors || []) as ListName[],
      version: registryEntry?.version || "",
      certifiedBy: (registryEntry?.certifiedBy || {}) as ListName,
      githubUrl: registryEntry?.githubUrl || "",
      docs: docs,
      groups,
      queries,
      variants: variants?.length ? [...defaultVariants, ...variants] : [],
      properties,
      platforms: (registryEntry?.platforms || []) as ContentPlatform[],
      cliCommand: registryEntry?.runCli?.command || "",
      installDocsURL: registryEntry?.runCli?.installDocsURL || "",
      emailLink,
      statistics,
      mutationStatistics,
    };
  }, [
    policyData?.policy,
    querypackData?.policy,
    state.isQuerypack,
    state.policyIndicator,
  ]);

  function replaceObjectByProperty(
    arr: Obj[],
    property: string,
    searchValue: any,
    replaceValue: Obj,
  ): void {
    const index = arr.findIndex((obj) => obj[property] === searchValue);

    if (index !== -1) {
      arr[index]["mql"] = replaceValue.mql;
    }
  }

  const filterOptions = useMemo(() => {
    if (!registryEntry.platforms.length) {
      return defaultFilterOptions;
    }

    return defaultFilterOptions.map((filterOption) => {
      if (filterOption.name === Filters.Platforms) {
        return {
          ...filterOption,
          options: map(registryEntry.platforms, "name"),
        };
      }

      return filterOption;
    });
  }, [registryEntry.platforms]);

  const registryByPlatform = useMemo(() => {
    return registryEntry.platforms.length
      ? registryEntry.platforms.map((registry) => ({
          name: registry.name,
          value: registry.name,
        }))
      : defaultPlatforms;
  }, [registryEntry.platforms]);

  const paginatedGroups = useMemo(() => {
    return registryEntry.groups.slice(state.from, state.to);
  }, [state.from, state.to, registryEntry.groups]);

  const registryTabOptions = useMemo(() => {
    return adjustedProps.length > 0 ? defaultRegistryTabOptions : [];
  }, [registryEntry.properties, adjustedProps]);

  const filters = useMemo(() => {
    return {
      impact: facets.impact,
      platforms: facets.platforms,
    };
  }, [facets.impact, facets.platforms]);

  const onChangeBreadcrumb = useCallback(() => {
    onFacetsChange(omit(defaultFacets, ["namespace", "uid"]));
    onStateChange(omit(defaultState, ["isReady", "isQuerypack"]));
    // Reset pagination
    onPaginationChange({ count: registryEntry.groups.length });
    onListPaginationChange({ count: registryEntry.queries.length });
  }, [registryEntry.groups, registryEntry.queries]);

  const onNavigateToQueries = useCallback(
    (query: MqueryType) => {
      onNavigate({
        pathname: `/registry/namespace/[namespace]/${
          isQuerypack ? "queries" : "checks"
        }/[uid]`,
        query: {
          namespace: query?.namespace || registryEntry.namespace,
          uid: query?.uid,
          registryUid: registryEntry.uid,
          registryEntryName: registryEntry.name,
          variant: facets.variant
            ? encodeURIComponent(facets.variant)
            : facets.variant,
        },
      });
    },
    [isQuerypack, registryEntry.uid, registryEntry.name, facets.variant],
  );

  // ToDo:NIT Move this to usePolicyIndicator
  const getPolicyIndicatorProps = useCallback((): PolicyIndicatorProps => {
    const input = {
      policyMrn: registryEntry.mrn,
      assetMrn: space.mrn,
      action: PolicyAction.Active,
    };
    const currentMode = registryEntry.action;

    return {
      loading: state.policyIndicator,
      indicator: getPolicyIndicatorState(currentMode),
      actions: {
        disablePolicy: async (e: SyntheticEvent | null) => {
          e?.stopPropagation();
          onStateChange({ policyIndicator: "disabled" });

          try {
            await unAssignPolicyMutation({
              variables: {
                input,
              },
            });
          } catch (error) {
            console.error(error);
          }

          onStateChange({ policyIndicator: null });
        },
        enablePolicy: async (e: SyntheticEvent | null) => {
          e?.stopPropagation();
          onStateChange({ policyIndicator: "enabled" });

          try {
            await assignPolicyMutation({
              variables: {
                input,
              },
            });
          } catch (error) {
            console.error(error);
          }

          onStateChange({ policyIndicator: null });
        },
        togglePreview: async (e: SyntheticEvent | null) => {
          e?.stopPropagation();
          onStateChange({ policyIndicator: "preview" });
          /**
           * If already preview mode do unassign to active,
           *  otherwise do assign to ignore
           */
          const isPreviewMode = currentMode === PolicyAction.Ignore;
          const policyMutation = isPreviewMode
            ? unAssignPolicyMutation
            : assignPolicyMutation;
          const action = PolicyAction[isPreviewMode ? "Active" : "Ignore"];

          try {
            await policyMutation({
              variables: {
                input: {
                  ...input,
                  action,
                },
              },
            });
          } catch (error) {
            console.error(error);
          }

          onStateChange({ policyIndicator: null });
        },
      },
    };
  }, [state.isQuerypack, state.policyIndicator, registryEntry]);

  const onChangeAssignHandler = useCallback((): Promise<any> => {
    const policyMutation = registryEntry.assigned
      ? unAssignPolicyMutation
      : assignPolicyMutation;

    return policyMutation({
      variables: {
        input: {
          policyMrn: registryEntry.mrn,
          assetMrn: space.mrn,
          action: PolicyAction.Active,
        },
      },
    });
  }, [unAssignPolicyMutation, assignPolicyMutation, registryEntry]);

  return {
    loading: state.isQuerypack ? querypackLoading : policyLoading,
    error: state.isQuerypack ? querypackError : policyError,
    filtersLoading: false,
    filtersErrors: [],
    filterOptions,
    registryTabOptions,
    viewOptions,
    registryByPlatform,
    registryEntry,
    paginatedGroups,
    facets,
    state,
    filters,
    onNavigate,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onTabChange,
    onVariantsChange,
    onPaginationChange,
    onNavigateToQueries,
    listPaginationText,
    onListPaginationChange,
    adjustedProps,
    onChangeAssignHandler,
    deletePolicyLoading,
    handleDeletePolicy,
    getPolicyIndicatorProps,
  };
};

export default useRegistry;
