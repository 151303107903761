import { useState, useCallback } from "react";
import {
  IconButton,
  Snackbar,
  Fade,
  Paper,
  Popper,
  Backdrop,
  DialogActions,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
  IconButtonProps,
} from "@mui/material";
import Flex from "../../layouts/Flex";
import Text from "../../typography/Text";
import { ShareLogo, CopyLinkIcon, EmailIcon } from "../../../icons";

type ShareButtonProps = {
  emailLink: string;
};

const ShareButton = ({ emailLink }: ShareButtonProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openNotification, setNotification] = useState(false);

  const handleClick: IconButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setNotification(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "share-transition-popper" : undefined;

  const onCopyLink = useCallback(() => {
    navigator.clipboard.writeText(String(window.location));
    setNotification(true);
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          ...(open ? { backgroundColor: "rgba(255, 255, 255, 0.08)" } : {}),
        }}
      >
        <ShareLogo />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        sx={{
          position: "relative",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={24}
              sx={{
                p: 2,
                mt: 2,
              }}
            >
              <Flex center position="absolute" width="100%" top={1} left={0}>
                <Arrow />
              </Flex>
              <DialogActions
                sx={{
                  p: 0,
                  gridGap: 2,
                  justifyContent: "flex-start",
                }}
              >
                <Text bold secondary>
                  SHARE
                </Text>
              </DialogActions>
              <MenuList>
                <MenuItem onClick={onCopyLink}>
                  <ListItemIcon>
                    <CopyLinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Copy link</ListItemText>
                </MenuItem>
                <a
                  href={emailLink}
                  style={{
                    textDecoration: "inherit",
                    color: "inherit",
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Email</ListItemText>
                  </MenuItem>
                </a>
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Backdrop
        open={open}
        onClick={() => {
          setOpen(false);
        }}
        sx={{ backgroundColor: "transparent", zIndex: 0 }}
      />
      <Snackbar
        open={openNotification}
        autoHideDuration={2000}
        message="Link copied to clipboard"
        onClose={() => {
          setNotification(false);
        }}
      />
    </>
  );
};

export default ShareButton;

const Arrow = styled("span")(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  borderBottom: `15px solid`,
  borderBottomColor: theme.palette.background.paper,
}));
