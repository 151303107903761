import { FormEvent, useCallback, useEffect, useReducer, useState } from "react";
import { FirebaseError, initializeApp } from "firebase/app";

import {
  AuthContext,
  AuthOptions,
  AuthProviderProps,
  authReducer,
  Dispatch,
  initialState,
  SignupProps,
} from "./auth-context";

import {
  Auth,
  AuthError,
  AuthErrorCodes,
  AuthProvider,
  createUserWithEmailAndPassword,
  EmailAuthCredential,
  EmailAuthProvider,
  getAuth,
  getRedirectResult,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  SAMLAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updatePassword,
  User,
  UserCredential,
} from "firebase/auth";

import { GeolockDialog } from "~/components/geolock-dialog";
import { passwordStrength } from "~/lib/password-strength";
import { Config } from "~/configuration_provider";
import { isSafari16Plus } from "~/lib/user-agent";

// Configure Firebase
const firebaseConfig = {
  apiKey: `${Config.VITE_FIREBASE_API_KEY}`,
  authDomain: `${Config.VITE_FIREBASE_AUTH_DOMAIN}`,
};

// Initialize the firebase application
initializeApp(firebaseConfig);

// Get the auth object from firebase
const auth: Auth = getAuth();

export * from "firebase/auth";
export { auth };

type AuthProviders = GoogleAuthProvider | GithubAuthProvider | OAuthProvider;

function getProvider(providerOption: AuthOptions): AuthProviders {
  switch (providerOption) {
    case "google":
      return new GoogleAuthProvider();
    case "github":
      return new GithubAuthProvider();
    case "msft":
      return new OAuthProvider("microsoft.com");
    default:
      throw new Error(`Not a recognized provider type: ${providerOption}`);
  }
}

export function FirebaseAuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [hasFetchedUser, setHasFetchedUser] = useState<boolean>(false);

  const signInWithProvider = async (auth: Auth, provider: AuthProvider) => {
    // Temporary work around for:
    // https://github.com/firebase/firebase-js-sdk/issues/6716
    if (isSafari16Plus()) {
      return signInWithPopup(auth, provider);
    }
    return signInWithRedirect(auth, provider);
  };

  const linkPendingEmailCredential = async (
    result: UserCredential,
    pendingEmailCredential: EmailAuthCredential,
  ) => {
    let pendingAuthMsg = {
      message: "Successfully connected email & password account.",
      variant: "success",
    };
    try {
      await linkWithCredential(result.user, pendingEmailCredential);
      window.location.reload();
    } catch (error) {
      let message = "Failed to connect email & password account.";
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.EMAIL_EXISTS:
          case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
            message = message + " Email is already in use.";
        }
      }
      // SnackbarProvider is unavailable within AuthProvider. Find a new approach.
      // enqueueSnackbar(message, { variant: "error" });
      pendingAuthMsg = { message, variant: "error" };
    } finally {
      window.sessionStorage.setItem(
        "pendingAuthMessage",
        JSON.stringify(pendingAuthMsg),
      );
    }
  };

  useEffect(() => {
    try {
      getRedirectResult(auth)
        .then(async (result) => {
          if (result?.operationType === "link") {
            window.sessionStorage.setItem(
              "pendingAuthMessage",
              JSON.stringify({
                message: "Successfully connected account.",
                variant: "success",
              }),
            );
          }
          if (result?.operationType === "reauthenticate") {
            const pendingEmailCredential = EmailAuthCredential.fromJSON(
              window.sessionStorage.getItem("pendingEmailCredential") || "null",
            );
            if (pendingEmailCredential) {
              await linkPendingEmailCredential(result, pendingEmailCredential);
              window.sessionStorage.removeItem("pendingEmailCredential");
            }
          }
        })
        .catch((error) => {
          console.error("redirect result error", error);
          let message = "Error";
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case AuthErrorCodes.EMAIL_EXISTS:
                message = "Failed to connect account. Email is already in use.";
              case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
                message =
                  "Failed to connect account. Account is already in use.";
            }
          }
          window.sessionStorage.setItem(
            "pendingAuthMessage",
            JSON.stringify({ message, variant: "error" }),
          );
        });

      onAuthStateChanged(auth, async (user: User | null) => {
        let sessionTimeout: ReturnType<typeof setTimeout> | null = null;

        if (user) {
          // Successful log in
          if (user.email && user.emailVerified) {
            // identify the user in heap analytics
            window.heap.identify(user.email);
          }
          const idTokenResult = await user.getIdTokenResult();

          // Enforce maximum session duration if it's specified.
          if (Config.VITE_SESSION_MAX_DURATION) {
            const sessionMaxDuration = parseInt(
              Config.VITE_SESSION_MAX_DURATION,
            );
            const sessionStartTime = new Date(idTokenResult.authTime).getTime();
            const sessionTimeUsed = Date.now() - sessionStartTime;
            const sessionTimeLeft = Math.max(
              0,
              sessionMaxDuration - sessionTimeUsed,
            );
            const sessionInvalidatedAt = new Date(Date.now() + sessionTimeLeft);
            console.info(
              "Max session duration is %sms. Session will be invalidated at: %s",
              sessionMaxDuration,
              sessionInvalidatedAt,
            );
            sessionTimeout = setTimeout(() => auth.signOut(), sessionTimeLeft);
          }

          dispatch({ type: "set user success", user, idTokenResult });
        } else {
          // Clear out the sessionTimeout if it exists.
          if (sessionTimeout !== null) {
            clearTimeout(sessionTimeout);
            sessionTimeout = null;
          }

          // Successful log out
          dispatch({
            type: "set user success",
            user: null,
            idTokenResult: null,
          });
        }
        setHasFetchedUser(true);
      });
    } catch (error) {
      // auth failure
      console.error("auth failure", error);
      dispatch({ type: "set user failure", error: error });
    }
  }, []);

  const loginWithProvider = async (
    event: FormEvent,
    dispatch: Dispatch,
    providerOption: AuthOptions,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
    const provider = getProvider(providerOption);
    try {
      await signInWithProvider(auth, provider);
    } catch (error) {
      console.log(
        "%c---- ERROR LOGGING IN WITH PROVIDER",
        "background: pink; color: black",
      );
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const loginWithSSO = async (
    event: FormEvent,
    dispatch: Dispatch,
    orgId: string,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
    try {
      // question for DOM do we need this for any particular reason?
      localStorage.setItem("saml.orgid", orgId);
      const provider = new SAMLAuthProvider(`saml.${orgId}`);
      await signInWithProvider(auth, provider);
    } catch (error) {
      console.log(
        "%c---- ERROR SIGNING IN WITH SSO",
        "background: pink; color: black",
      );
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const loginWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    { email, password }: { email: string; password: string },
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const signupWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    { email, password, optIn }: SignupProps,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });

    revalidatePassword(password);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      // get current user and set display name
      // await updateProfile(userCredential.user, {   <==== what do we want to do with this?
      //   displayName: `${firstName} ${lastName}`,
      // });
      // let subscriptions = [
      //   {
      //     listID: EmailPreferenceList.NotificationWeeklyReports,
      //     subscribe: true,
      //   },
      //   {
      //     listID: EmailPreferenceList.NotificationSpaceAlerts,
      //     subscribe: true,
      //   },
      // ];

      // if (optIn) {
      //   subscriptions.concat([
      //     { listID: EmailPreferenceList.NewsletterEvents, subscribe: true },
      //     { listID: EmailPreferenceList.NewsletterGeneral, subscribe: true },
      //     { listID: EmailPreferenceList.NewsletterProduct, subscribe: true },
      //   ]);
      // }
      // Apollo isn't yet hooked up
      // setEmailPreference({ variables: { input: subscriptions } });
    } catch (error) {
      console.log(
        "%c---- ERROR CREATING USER",
        "background: pink; color: black",
      );
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const logout = async () => {
    try {
      // reset heap analytics identity for a new anonymous session
      window.heap.resetIdentity();

      await signOut(auth);
      location.reload();
    } catch (error) {
      console.log("%c---- ERROR LOGGING OUT", "background: pink; color: black");
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const sendResetPasswordEmail = async (
    dispatch: Dispatch,
    email: string,
    event?: FormEvent,
  ) => {
    if (event) {
      // we now send a password without a form event, so the event is optional
      event.preventDefault();
    }
    dispatch({ type: "reset password begin" });
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch({ type: "reset password success" });
    } catch (error) {
      console.log(
        "%c---- ERROR RESETTING PASSWORD",
        "background: pink; color: black",
      );
      dispatch({ type: "reset password failure" });
    }
  };

  const sendUserEmailVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
      }
    } catch (error) {
      const message = handleErrorMessage((error as AuthError).code);
      throw message;
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<void> => {
    const { user } = state;
    if (!user?.email) {
      return Promise.reject("Unable to update password");
    }
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    return updatePassword(user, newPassword);
  };

  const clearErrors = useCallback(() => {
    dispatch({ type: "clear errors" });
  }, []);

  const setGeolock = (isGeolocked: boolean) => {
    dispatch({ type: "set geolock", isGeolocked });
  };

  // Error codes can be found at
  // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error#code
  const handleErrorMessage = (errorCode: string) => {
    let customError = "Oops, something went wrong. Please give it another try.";
    switch (errorCode) {
      case "auth/user-not-found":
      case "auth/wrong-password":
        return "Hmmmm... We don't seem to have a record of that username/password combination.";
      case "auth/network-request-failed":
        return "Uh oh, we seem to have experienced a network error. Please try again.";
      case "auth/too-many-requests":
        return "There's been a few too many requests from this device. Please wait a few minutes and then try again.";
      case "auth/user-token-expired": // fallthrough
      case "auth/invalid-user-token":
        return "Looks like it's been a while since you've been authenticated, please sign in again.";
      case "auth/weak-password":
        return "Come on now, we need you to use a stronger password than that. Something with at least 6 characters would be great.";
      case "auth/invalid-action-code":
        return "The reset password code provided has already been used or is no longer valid.";
      default:
        return customError;
    }
  };

  // We check again that our passwords are upheld to our chosen standard.
  // This must stay in sync with the checks set in signup.tsx
  const revalidatePassword = (password: string) => {
    // Check against our chosen password length rules
    if (password.length > 100 || password.length < 8) {
      const message = "Your password must be between 8 and 100 characters";
      dispatch({ type: "set user failure", error: message });
      throw message;
    }

    // check again that we have a strict password score.
    const result = passwordStrength(password);
    if (result.score < 3) {
      const suggestions = result.feedback.suggestions.join(" ");
      const warning = result.feedback.warning;
      const message = `Please use a more complex password. ${
        warning ? warning + "." : ""
      } ${suggestions}`;
      dispatch({ type: "set user failure", error: message });
      throw message;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        logout,
        loginWithProvider,
        signupWithEmailAndPassword,
        loginWithEmailAndPassword,
        loginWithSSO,
        sendResetPasswordEmail,
        sendUserEmailVerification,
        changePassword,
        clearErrors,
        setGeolock,
        handleErrorMessage,
      }}
    >
      {hasFetchedUser && children}
      {state.isGeolocked && <GeolockDialog />}
    </AuthContext.Provider>
  );
}
