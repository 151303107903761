import { useParams } from "react-router-dom";
import { Space } from "~/lib/types";

type UseGenerateIntegrationNameProps = {
  space: Space;
};

const useGenerateIntegrationName = ({
  space,
}: UseGenerateIntegrationNameProps): string => {
  const { integrationTypeId } = useParams();
  const spaceName = String(space?.name);
  const uniqueCode = generateUniqueCode();

  return `${normalizeString(spaceName)}-${normalizeIntegrationName(
    String(integrationTypeId),
  )}-${uniqueCode}`;
};

export default useGenerateIntegrationName;

const normalizeString = (val: string) => {
  return val?.toLowerCase().replace(/\s/g, "-");
};

const generateUniqueCode = () => {
  const code = Math.floor(Math.random() * 10000);
  return code.toString().padStart(4, "0");
};

const normalizeIntegrationName = (val: string) => {
  let normalized = val?.toLowerCase();
  switch (normalized) {
    case "azure":
      normalized = "Azure";
      break;
    case "azure_blob":
      normalized = "Azure Blob Storage";
      break;
    case "aws":
      normalized = "AWS";
      break;
    case "amazons3":
      normalized = "Amazon S3";
      break;
    case "amazon-s3-compatible":
      normalized = "S3 Compatible Service";
      break;
    case "bigquery":
      normalized = "BigQuery";
      break;
    case "gcp":
      normalized = "GCP";
      break;
    case "gcs_bucket":
      normalized = "GCP Cloud Storage Bucket";
      break;
    case "google_workspace":
      normalized = "Google Workspace";
      break;
    case "github":
      normalized = "GitHub";
      break;
    case "gitlab":
      normalized = "GitLab";
      break;
    case "ticket_system_jira":
      normalized = "Atlassian Jira";
      break;
    case "ticket_system_email":
      normalized = "Ticketing via email";
      break;
    case "ticket_system_zendesk":
      normalized = "Zendesk";
      break;
    case "kubernetes":
      normalized = "Kubernetes";
      break;
    case "postgres":
      normalized = "PostgreSQL";
      break;
    case "slack":
      normalized = "Slack";
      break;
    case "snowflake":
      normalized = "Snowflake";
      break;
    case "ms365":
      normalized = "MS365";
      break;
    case "okta":
      normalized = "Okta";
      break;
    case "oci":
      normalized = "OCI";
      break;
    case "azure-blob":
      normalized = "Azure Blob Storage";
      break;
    default:
      break;
  }

  return normalized?.replace(/\s/g, "-");
};
